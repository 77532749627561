import React from 'react'

import { useTextField, useSelectField } from '@maruware/material-ui-hooks'
import TextField from '@material-ui/core/TextField'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'

import validator from 'validator'

import { makeStyles, createStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

import { useSnackbar } from 'notistack'

const useStyles = makeStyles(theme =>
  createStyles({
    content: {
      minWidth: 400
    },
    form: {
      display: 'flex',
      flexDirection: 'column'
    },
    field: {
      marginBottom: theme.spacing(2)
    }
  })
)

export interface NewAdminUserDialogProps extends DialogProps {
  onRegist: (username: string, password: string, role: string) => Promise<void>
  onClose: () => void
}

export const NewAdminUserDialog: React.FC<NewAdminUserDialogProps> = ({
  onRegist,
  ...rest
}) => {
  const [username, handleChangeUsername] = useTextField('')
  const [password, handleChangePassword] = useTextField('')
  const [passwordConfirm, handleChangePasswordConfirm] = useTextField('')
  const [role, handleChangeRole] = useSelectField('')

  const validUsername = username && validator.isEmail(username)

  const valid =
    validUsername &&
    password &&
    passwordConfirm &&
    password === passwordConfirm &&
    role
  const notMatchPassword =
    Boolean(password) &&
    Boolean(passwordConfirm) &&
    password !== passwordConfirm

  const snackbar = useSnackbar()

  const handleRegist = () => {
    if (!username || !password || !role) {
      throw new Error('Unexpected')
    }
    onRegist(username, password, role).catch(() => {
      snackbar.enqueueSnackbar('登録に失敗しました', { variant: 'error' })
    })
  }

  const classes = useStyles({})
  return (
    <Dialog {...rest}>
      <DialogTitle>新しいアカウント</DialogTitle>
      <DialogContent className={classes.content}>
        <form className={classes.form}>
          <TextField
            label="ユーザーID(E-mailアドレス)"
            value={username}
            onChange={handleChangeUsername}
            className={classes.field}
            error={!validUsername}
          />
          <TextField
            label="パスワード"
            value={password}
            onChange={handleChangePassword}
            type="password"
            className={classes.field}
            helperText="8文字以上。英数字+記号。記号1文字以上含む。"
          />
          <TextField
            label="パスワード(確認)"
            value={passwordConfirm}
            onChange={handleChangePasswordConfirm}
            type="password"
            className={classes.field}
            error={notMatchPassword}
            helperText={notMatchPassword && 'パスワードが異なっています'}
          />
          <FormControl className={classes.field}>
            <InputLabel>ロール</InputLabel>
            <Select value={role} onChange={handleChangeRole}>
              <MenuItem value="normal">制限アカウント</MenuItem>
              <MenuItem value="super">管理アカウント</MenuItem>
            </Select>
          </FormControl>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={rest.onClose}>キャンセル</Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!valid}
          onClick={handleRegist}
        >
          登録する
        </Button>
      </DialogActions>
    </Dialog>
  )
}
