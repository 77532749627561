import React from 'react'

import { MainImageKind, ArtistNameKind } from '../types'
import { PhotoFrame } from './PhotoFrame'
import { Size } from '../../../lib/imaging'
import { XY, copyrightTapeHeight } from './arrangement'
const maskColor = '#fff'

interface ArtistNameDateProps {
  artistNamePos: XY
  artistNameBBox: DOMRect
  artistNameWritableMargin: number
  datePos: XY
  dateBBox: DOMRect

  rotation: number
}
const ArtistNameDate: React.FC<ArtistNameDateProps> = ({
  artistNamePos,
  artistNameBBox,
  datePos,
  dateBBox,
  artistNameWritableMargin,
  rotation
}) => {
  if (artistNameBBox.width <= 0 || artistNameBBox.height <= 0) {
    return <g></g>
  }

  const artistRect = {
    x: artistNamePos.x + artistNameBBox.x + artistNameWritableMargin,
    y: artistNamePos.y + artistNameBBox.y + artistNameWritableMargin,
    width: artistNameBBox.width - artistNameWritableMargin * 2,
    height: artistNameBBox.height - artistNameWritableMargin * 2
  }
  const dateRect = {
    x: datePos.x + dateBBox.x,
    y: datePos.y + dateBBox.y,
    width: dateBBox.width,
    height: dateBBox.height
  }

  return (
    <g>
      <g
        transform={`translate(${artistRect.x}, ${
          artistRect.y
        }) rotate(${rotation}, ${artistRect.width / 2}, 0)`}
      >
        <rect
          width={artistRect.width}
          height={artistRect.height}
          fill={maskColor}
        />
      </g>

      <g
        transform={`translate(${dateRect.x}, ${
          dateRect.y
        }) rotate(${rotation}, ${dateRect.width / 2}, 0)`}
      >
        <rect
          width={dateRect.width}
          height={dateRect.height}
          fill={maskColor}
        />
      </g>
    </g>
  )
}

interface RestrictImageSvgProps {
  className?: string
  svgRef: React.RefObject<SVGSVGElement>
  width: number
  height: number
  mainImageKind: MainImageKind
  photoFrame: PhotoFrame
  illustSize: Size
  artistNameKind: ArtistNameKind
  artistNamePos: XY
  artistNameBBox: DOMRect
  rotation: number
  datePos: XY
  dateBBox: DOMRect
  artistNameWritableMargin: number
  copyrightMaskPolygon: XY[]
}
const RestrictImageSvg: React.FC<RestrictImageSvgProps> = ({
  className,
  svgRef,
  width,
  height,
  mainImageKind,
  illustSize,
  photoFrame,
  artistNameKind,
  artistNamePos,
  artistNameBBox,
  rotation,
  datePos,
  dateBBox,
  artistNameWritableMargin,
  copyrightMaskPolygon
}) => {
  const enableMainImage = mainImageKind >= 1

  return (
    <svg
      ref={svgRef}
      width={width}
      height={height}
      className={className}
      viewBox={`0 0 ${width} ${height}`}
    >
      {enableMainImage && (
        <>
          {/* メイン */}
          {mainImageKind === MainImageKind.Illust && (
            <rect
              x={(width - illustSize.width) / 2 + 40}
              y={200 + 40}
              width={illustSize.width > 0 ? illustSize.width - 40 * 2 : 0}
              height={illustSize.height > 0 ? illustSize.height - 40 * 1 : 0}
              fill={maskColor}
            />
          )}
          {mainImageKind === MainImageKind.Photo && (
            <rect
              x={photoFrame.restrict.x}
              y={photoFrame.restrict.y}
              width={photoFrame.restrict.width}
              height={photoFrame.restrict.height}
              fill={maskColor}
            />
          )}
        </>
      )}

      {/* テキスト */}
      <ArtistNameDate
        artistNamePos={artistNamePos}
        artistNameBBox={artistNameBBox}
        artistNameWritableMargin={artistNameWritableMargin}
        datePos={datePos}
        dateBBox={dateBBox}
        rotation={rotation}
      />

      {/* QR部分 */}
      <rect x={16} y={508} width={210} height={327} fill={maskColor} />
      <rect x={3614} y={508} width={210} height={327} fill={maskColor} />

      {/* copyright部分 */}
      {copyrightMaskPolygon && (
        <>
          {mainImageKind === MainImageKind.Illust && (
            <g
              transform={`translate(${artistNamePos.x}, ${705 -
                15 -
                copyrightTapeHeight}) rotate(-3)`}
            >
              <polygon
                points={copyrightMaskPolygon
                  .map(({ x, y }) => `${x},${y}`)
                  .join(' ')}
                fill="#fff"
              />
            </g>
          )}

          {mainImageKind === MainImageKind.None &&
            artistNameKind === ArtistNameKind.Logo && (
              <g
                transform={`translate(${datePos.x}, ${datePos.y +
                  dateBBox.height +
                  15})`}
              >
                <polygon
                  points={copyrightMaskPolygon
                    .map(({ x, y }) => `${x},${y}`)
                    .join(' ')}
                  fill="#fff"
                />
              </g>
            )}
        </>
      )}
    </svg>
  )
}

export default RestrictImageSvg
