import React, { useState, useEffect } from 'react'

import { Link as RouterLink, useRouteMatch } from 'react-router-dom'

import Link from '@material-ui/core/Link'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import InfoIcon from '@material-ui/icons/Info'

import { PseudoScreen, ConcertDetail } from '../../lib/api_client'
import useAuthedApiClient from '../../lib/useAuthedApiClient'

import DefaultLayout from '../../layout/DefaultLayout'
import Card from '@material-ui/core/Card'
import CardMedia from '@material-ui/core/CardMedia'
import CardHeader from '@material-ui/core/CardHeader'
import IconButton from '@material-ui/core/IconButton'
import DownloadIcon from '@material-ui/icons/GetApp'
import download from 'downloadjs'

import { DateTime } from 'luxon'
import Tooltip from '@material-ui/core/Tooltip'

const useStyles = makeStyles(theme => ({
  breadcrumbs: {
    paddingBottom: theme.spacing(1)
  },
  item: {
    margin: theme.spacing(2)
  },
  media: {
    height: 0,
    paddingTop: '28.125%' // 32:9
  },
  hint: {
    display: 'flex',
    justifyContent: 'flex-end',
    color: theme.palette.grey[700],
    marginRight: theme.spacing(2)
  }
}))

interface SnapShotListProps {}
const SnapShotList: React.FC<SnapShotListProps> = () => {
  const match = useRouteMatch()
  const id = match.params.id

  const classes = useStyles()

  const [items, setItems] = useState<PseudoScreen[]>([])
  const client = useAuthedApiClient()

  useEffect(() => {
    if (client) {
      client.getSnapshotPseudoScreens(id).then(d => setItems(d))
    }
  }, [client, id])

  const [concert, setConcert] = useState<ConcertDetail | null>(null)

  useEffect(() => {
    client && client.getConcert(id).then(c => setConcert(c))
  }, [client, id])

  const handleDownload = (url: string) => async () => {
    download(url + `?t=${Date.now()}`)
  }

  return (
    <DefaultLayout title="スナップショット">
      <Breadcrumbs className={classes.breadcrumbs}>
        <Link component={RouterLink} to="/">
          コンサート
        </Link>
        <Link component={RouterLink} to={`/concerts/${id}/show`}>
          {concert && concert.name}
        </Link>
        <Typography color="textPrimary">スナップショット</Typography>
      </Breadcrumbs>

      <div className={classes.hint}>
        <InfoIcon />
        <Typography>
          スナップショットは300枚目ごとの擬似スクリーンを表示します
        </Typography>
      </div>

      <div>
        {items.map(item => (
          <Card key={item.number} className={classes.item}>
            <CardHeader
              title={`${item.number}枚目`}
              subheader={DateTime.fromISO(item.rendered_at).toFormat(
                'y/LL/dd HH:mm'
              )}
              action={
                <Tooltip title="ダウンロード">
                  <IconButton onClick={handleDownload(item.image_url)}>
                    <DownloadIcon />
                  </IconButton>
                </Tooltip>
              }
            />
            <CardMedia image={item.image_url} className={classes.media} />
          </Card>
        ))}

        {concert && concert.last_pseudo_screen_image_url && (
          <Card className={classes.item}>
            <CardHeader
              title="最終"
              action={
                <Tooltip title="ダウンロード">
                  <IconButton
                    onClick={handleDownload(
                      concert.last_pseudo_screen_image_url
                    )}
                  >
                    <DownloadIcon />
                  </IconButton>
                </Tooltip>
              }
            />
            <CardMedia
              image={concert.last_pseudo_screen_image_url}
              className={classes.media}
            />
          </Card>
        )}
      </div>
    </DefaultLayout>
  )
}
export default SnapShotList
