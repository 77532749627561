import React, { useCallback } from 'react'
// import Button from '@material-ui/core/Button'

import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { logout } from '../actions'

const LogoutButton: React.FC = ({ children }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const handleLogout = useCallback(() => {
    dispatch(logout())
    history.push('/login')
  }, [])
  return <span onClick={handleLogout}>{children}</span>
}

export default LogoutButton
