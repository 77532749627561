import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import BaseField from './BaseField'

import { DateTime } from 'luxon'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  value: {
    fontSize: '14pt'
  }
}))
interface DateFieldProps {
  name: string
  value: string
}
const DateField: React.FC<DateFieldProps> = ({ name, value }) => {
  const classes = useStyles()
  const date = DateTime.fromISO(value)
  return (
    <div className={classes.root}>
      <BaseField name={name}>
        <span className={classes.value}>{date.toFormat('y/L/dd HH:mm')}</span>
      </BaseField>
    </div>
  )
}

export default DateField
