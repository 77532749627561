import React from 'react'

import UserMessageList from './UserMessageList'

export const NormalUserMessageList: React.FC = () => {
  return <UserMessageList kind="normal" />
}

export const ArtistUserMessageList: React.FC = () => {
  return <UserMessageList kind="artist" />
}
