import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

import { DataTypeProvider } from '@devexpress/dx-react-grid'

const useStyles = makeStyles(theme => ({
  image: {
    background: theme.palette.grey[700],
    width: '100%'
    // height: 40
  }
}))

const ImageFormatter: React.FC<DataTypeProvider.ValueFormatterProps> = ({
  value
}) => {
  const classes = useStyles()
  if (value) {
    return <img src={value} className={classes.image} />
  } else {
    return <></>
  }
}

export default ImageFormatter
