import React from 'react'

import { MainImageKind, ArtistNameKind } from '../types'
import { PhotoFrame } from './PhotoFrame'
import { XY } from './arrangement'

interface MaskForJointMaskImageSvgProps {
  className?: string
  svgRef: React.RefObject<SVGSVGElement>
  width: number
  height: number
  mainImageKind: MainImageKind
  photoFrame: PhotoFrame
  artistNameKind: ArtistNameKind
  artistNamePos: XY
  datePos: XY
  dateBBox: DOMRect
  copyrightMaskPolygon: XY[]
}
const MaskForJointMaskImageSvg: React.FC<MaskForJointMaskImageSvgProps> = ({
  className,
  svgRef,
  width,
  height,
  mainImageKind,
  photoFrame,
  artistNameKind,
  artistNamePos,
  datePos,
  dateBBox,
  copyrightMaskPolygon
}) => {
  return (
    <svg
      ref={svgRef}
      width={width}
      height={height}
      className={className}
      viewBox={`0 0 ${width} ${height}`}
    >
      {mainImageKind === MainImageKind.Photo && (
        <rect
          x={photoFrame.out.x}
          y={photoFrame.out.y}
          width={photoFrame.out.width}
          height={photoFrame.out.height}
          fill="#000"
        />
      )}

      {copyrightMaskPolygon && (
        <>
          {mainImageKind === MainImageKind.Illust && (
            <g transform={`translate(${artistNamePos.x}, ${715}) rotate(-3)`}>
              <polygon
                points={copyrightMaskPolygon
                  .map(({ x, y }) => `${x},${y}`)
                  .join(' ')}
                fill="#fff"
              />
            </g>
          )}

          {mainImageKind === MainImageKind.None &&
            artistNameKind === ArtistNameKind.Logo && (
              <g
                transform={`translate(${datePos.x}, ${datePos.y +
                  dateBBox.height +
                  15})`}
              >
                <polygon
                  points={copyrightMaskPolygon
                    .map(({ x, y }) => `${x},${y}`)
                    .join(' ')}
                  fill="#fff"
                />
              </g>
            )}
        </>
      )}
    </svg>
  )
}

export default MaskForJointMaskImageSvg
