import { ArtistNameKind, MainImageKind } from '../types'

export const getArtistNameFontSetting = (
  artistNameKind: ArtistNameKind,
  artistName: string
) => {
  let size = 180
  let writableMargin = 0
  let letterSpacing = 0
  if (artistNameKind === ArtistNameKind.En) {
    if (artistName.length <= 7) {
      writableMargin = 20
      size = 180
    } else if (artistName.length <= 16) {
      writableMargin = 15
      size = 154
    } else {
      writableMargin = 5
      size = (153 * 16) / artistName.length
    }
  } else if (artistNameKind === ArtistNameKind.Ja) {
    if (artistName.length <= 7) {
      writableMargin = 20
      size = 170
      letterSpacing = -0
    } else if (artistName.length <= 10) {
      writableMargin = 15
      size = 154
      letterSpacing = 0
    } else {
      writableMargin = 5
      size = (154 * 10) / artistName.length
      letterSpacing = -6
    }
  } else if (artistNameKind === ArtistNameKind.Logo) {
    size = 170
  }
  return { size, writableMargin, letterSpacing }
}

export interface XY {
  x: number
  y: number
}

export const getArtistNameAndDatePos = (
  canvasWidth: number,
  canvasHeight: number,
  mainImageKind: MainImageKind,
  artistNameFontSize: number,
  dateFontSize: number
): { artistNamePos: XY; datePos: XY } => {
  const artistNameX = canvasWidth / 2
  let artistNameY = 0
  const dateX = canvasWidth / 2
  let dateY = 0
  if (mainImageKind === MainImageKind.Illust) {
    artistNameY = 705 - 15 // 微調整
  } else if (mainImageKind === MainImageKind.Photo) {
    artistNameY = 715 - 15 // 微調整
  } else {
    // 中央
    artistNameY =
      canvasHeight / 2 - (artistNameFontSize + 15 + dateFontSize) / 2
  }
  dateY = artistNameY + artistNameFontSize + 15

  return {
    artistNamePos: { x: artistNameX, y: artistNameY },
    datePos: { x: dateX, y: dateY }
  }
}

export const copyrightTapeHeight = 22
