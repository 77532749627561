import TextColor from './TextColor'

// export type ArtistNameKind = -1 | 0 | 1 | 2
export enum ArtistNameKind {
  None = -1,
  En = 0,
  Ja = 1,
  Logo = 2
}
export type ArtistNameColor = TextColor
export type DateColor = TextColor.White | TextColor.Black
export enum MainImageKind {
  Undefined = -1,
  None = 0,
  Illust = 1,
  Photo = 2
}
export enum MainImagePhotoFrame {
  Portrait = 0,
  Square,
  Landscape
}
export type BgKind = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8
