import { SET_USER_ACTION, LOGOUT_ACTION, AuthAction } from '../reducers/auth'

export const setUser = (
  username: string,
  role: string,
  token: string
): AuthAction => {
  return {
    type: SET_USER_ACTION,
    username,
    role,
    token
  }
}

export const logout = (): AuthAction => {
  return {
    type: LOGOUT_ACTION
  }
}
