import React, { useState, useCallback } from 'react'

import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import { blueGrey } from '@material-ui/core/colors'

import { useSnackbar } from 'notistack'

import { createApiClient } from '../lib/api_client'
import { setUser } from '../actions'
import { AxiosError } from 'axios'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    backgroundColor: blueGrey[800],
    justifyContent: 'center',
    alignItems: 'flex-start'
  },
  card: {
    marginTop: theme.spacing(10),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  fieldsContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
    width: 300
  },
  actions: {
    display: 'flex',
    justifyContent: 'center'
  },
  loginButton: {
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(6)
  }
}))

const apiClient = createApiClient()

interface LoginProps {}
const Login: React.FC<LoginProps> = () => {
  const classes = useStyles()

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const handleChangeUsername = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setUsername(e.target.value)
    },
    []
  )
  const handleChangePassword = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setPassword(e.target.value)
    },
    []
  )

  const dispatch = useDispatch()
  const snack = useSnackbar()
  const history = useHistory()
  const location = useLocation()

  const signIn = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault()
      apiClient
        .post('/admin/login', { username, password })
        .then(res => {
          const { username, role, token } = res.data
          console.log('login res:', res.data)
          dispatch(setUser(username, role, token))

          const { from } = location.state || { from: '/' }
          history.push(from)
        })
        .catch((err: AxiosError) => {
          if (err.response && err.response.data.code === 'LockedAccount') {
            snack.enqueueSnackbar(
              'アカウントはロックされています。管理者に問い合わせてください。',
              {
                variant: 'error'
              }
            )
            return
          }
          snack.enqueueSnackbar('認証に失敗しました', { variant: 'error' })
        })
    },
    [username, password]
  )

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <form onSubmit={signIn}>
          <CardContent className={classes.fieldsContainer}>
            <TextField
              label="ユーザー名"
              className={classes.textField}
              value={username}
              onChange={handleChangeUsername}
            />
            <TextField
              label="パスワード"
              type="password"
              className={classes.textField}
              value={password}
              onChange={handleChangePassword}
            />
          </CardContent>
          <CardActions className={classes.actions}>
            <Button
              color="primary"
              variant="contained"
              size="large"
              type="submit"
              className={classes.loginButton}
            >
              ログイン
            </Button>
          </CardActions>
        </form>
      </Card>
    </div>
  )
}

export default Login
