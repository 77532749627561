import React from 'react'
import orgConfig from '../../../bmp_font.json'
import TextColor from '../TextColor'

interface CharDef {
  url: string
  width: number
  height: number
}

const config = orgConfig as {
  [color: string]: {
    [char: string]: CharDef
  }
}

// type Color = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8

interface BmpCharProps {
  url: string
  x: number
  width: number
  height: number
}
const BmpChar: React.FC<BmpCharProps> = ({ x, url, width, height }) => {
  const u = `data:image/png;base64,${url}`
  return <image x={x} y="0" xlinkHref={u} width={width} height={height} />
}

interface BmpTextProps {
  color: TextColor
  text: string
  fontSize: number
  textAnchor: string
}

interface BmpTextState {
  charDefs: CharDef[]
}
class BmpText extends React.Component<BmpTextProps, BmpTextState> {
  constructor(props: BmpTextProps) {
    super(props)
    this.state = {
      charDefs: []
    }
  }

  componentDidMount() {
    this.updateCharDefs()
  }

  componentDidUpdate(prevProps: BmpTextProps) {
    if (
      prevProps.text !== this.props.text ||
      prevProps.color !== this.props.color
    ) {
      this.updateCharDefs()
    }
  }

  updateCharDefs() {
    const { text, color } = this.props
    const chars = Array.from(text.toUpperCase())
    const cs = color.toString()
    if (!(cs in config)) {
      throw new Error(`Bad color: ${color}`)
    }

    const charMap = config[cs]

    const charDefs = chars.map(char => {
      if (!(char in charMap)) {
        throw new Error(`Bad character: "${char}"`)
      }
      return charMap[char]
    })

    this.setState({ charDefs })
  }

  getBBox(): DOMRect {
    const { fontSize, textAnchor } = this.props

    const sumWidth = this.state.charDefs.reduce(
      (sum, d) => sum + (d.width / d.height) * fontSize,
      0
    )

    if (textAnchor === 'middle') {
      return new DOMRect(-sumWidth / 2, 0, sumWidth, fontSize)
    }
    return new DOMRect(0, 0, sumWidth, fontSize)
  }

  render() {
    const { charDefs } = this.state
    const { fontSize } = this.props

    const bbox = this.getBBox()
    const offsetX = bbox.x

    let currX = 0

    return (
      <g transform={`translate(${offsetX}, 0)`}>
        {charDefs.map((d, i) => {
          const { url, width, height } = d
          // scaling
          const h = fontSize
          const w = (width / height) * fontSize

          const x = currX
          currX += w
          return <BmpChar key={i} x={x} url={url} width={w} height={h} />
        })}
      </g>
    )
  }
}

export default BmpText
