import React from 'react'
import Typography from '@material-ui/core/Typography'

import { makeStyles, createStyles } from '@material-ui/core/styles'
const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    label: {
      color: theme.palette.grey[600]
    }
  })
)

export interface CountSummaryProps {
  label: string
  count: number
}

export const CountSummary: React.FC<CountSummaryProps> = ({ label, count }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Typography className={classes.label}>{label}</Typography>
      <Typography variant="h4">{count}</Typography>
    </div>
  )
}
