import React from 'react'

import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'

import { useTextField } from '@maruware/material-ui-hooks'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'

import useAuthedApiClient from '../lib/useAuthedApiClient'

const useStyles = makeStyles(theme =>
  createStyles({
    content: {
      minWidth: 400
    },
    form: {
      display: 'flex',
      flexDirection: 'column'
    },
    field: {
      marginBottom: theme.spacing(2)
    }
  })
)

export interface ChangePasswordDialogProps extends DialogProps {
  onClose: () => void
}

export const ChangePasswordDialog: React.FC<ChangePasswordDialogProps> = ({
  ...rest
}) => {
  const [password, handleChangePassword] = useTextField('')
  const [passwordConfirm, handleChangePasswordConfirm] = useTextField('')

  const valid = password && passwordConfirm && password === passwordConfirm

  const notMatchPassword =
    Boolean(password) &&
    Boolean(passwordConfirm) &&
    password !== passwordConfirm

  const client = useAuthedApiClient()

  const handleUpdate = async () => {
    if (client) {
      if (!password) {
        throw new Error('unexpected')
      }
      await client.changePassword(password)
      rest.onClose()
    }
  }

  const classes = useStyles({})
  return (
    <Dialog {...rest}>
      <DialogTitle>パスワードの変更</DialogTitle>
      <DialogContent className={classes.content}>
        <form className={classes.form}>
          <TextField
            label="パスワード"
            value={password}
            onChange={handleChangePassword}
            type="password"
            className={classes.field}
          />
          <TextField
            label="パスワード(確認)"
            value={passwordConfirm}
            onChange={handleChangePasswordConfirm}
            type="password"
            className={classes.field}
            error={notMatchPassword}
            helperText={notMatchPassword && 'パスワードが異なっています'}
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={rest.onClose}>キャンセル</Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!valid}
          onClick={handleUpdate}
        >
          変更する
        </Button>
      </DialogActions>
    </Dialog>
  )
}
