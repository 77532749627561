import React from 'react'
import clsx from 'clsx'
import Radio, { RadioProps } from '@material-ui/core/Radio'

import { makeStyles, createStyles } from '@material-ui/core/styles'

const useStylesImg = makeStyles(theme =>
  createStyles({
    root: {
      padding: 2,
      display: 'flex',
      alignItems: 'center'
    },
    checked: {
      borderWidth: 2,
      borderStyle: 'solid',
      borderColor: theme.palette.secondary.main,
      borderRadius: 3
    }
  })
)

interface ImgProps {
  src: string
  checked: boolean
}

const Img: React.FC<ImgProps> = ({ src, checked }) => {
  const classes = useStylesImg({})
  return (
    <div className={clsx(classes.root, { [classes.checked]: checked })}>
      <img src={src} />
    </div>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    radio: {
      padding: 4
    }
  })
)

export const ImgRadio: React.FC<{ src: string } & RadioProps> = ({
  src,
  ...rest
}) => {
  const classes = useStyles({})
  return (
    <Radio
      className={classes.radio}
      disableRipple
      color="default"
      checkedIcon={<Img src={src} checked={true} />}
      icon={<Img src={src} checked={false} />}
      {...rest}
    />
  )
}
