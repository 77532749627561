import { createMuiTheme } from '@material-ui/core/styles'
import { blue, orange } from '@material-ui/core/colors'

const theme = createMuiTheme({
  palette: {
    primary: {
      light: blue[500],
      main: blue[600],
      dark: blue[800]
    },
    secondary: {
      light: orange[300],
      main: orange[500],
      dark: orange[800]
    }
  }
})

export default theme
