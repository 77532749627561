import React from 'react'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  name: {
    fontWeight: 'bold',
    fontSize: '11pt'
  },
  value: {
    marginLeft: theme.spacing(2)
  }
}))
interface Props {
  name: string
}
const BaseField: React.FC<Props> = ({ name, children }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.name}>{name}</div>
      <div className={classes.value}>{children}</div>
    </div>
  )
}

export default BaseField
