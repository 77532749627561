import React, { useState, useEffect } from 'react'
import QRCode from 'qrcode'

export interface QRCodeImageProps {
  value: string
}
const QRCodeImage: React.FC<
  QRCodeImageProps & React.ImgHTMLAttributes<HTMLImageElement>
> = ({ value, ...rest }) => {
  const [dataUrl, setDataUrl] = useState('')

  const options: QRCode.QRCodeToDataURLOptions = {}
  if (rest.width) {
    options.width = parseInt(rest.width.toString())
  }
  options.margin = 0
  useEffect(() => {
    QRCode.toDataURL(value, options).then(url => setDataUrl(url))
  }, [value])

  return <img src={dataUrl} {...rest} />
}

export default QRCodeImage
