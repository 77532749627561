import React, { useEffect, useState } from 'react'

import { useHistory, useRouteMatch } from 'react-router-dom'

import {
  ConcertDetail,
  PostConcertData,
  ConcertDupTimeError
} from '../../lib/api_client'
import useAuthedApiClient from '../../lib/useAuthedApiClient'

import LinearProgress from '@material-ui/core/LinearProgress'
import { useSnackbar } from 'notistack'

// import { makeStyles } from '@material-ui/core/styles'

import ConcertForm from './ConcertForm'
import {
  ArtistNameKind,
  ArtistNameColor,
  MainImageKind,
  BgKind,
  MainImagePhotoFrame,
  DateColor
} from './types'
import DefaultLayout from '../../layout/DefaultLayout'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  root: {}
}))

interface IdMatch {
  id: string
}
interface ConcertEditProps {}
const ConcertEdit: React.FC<ConcertEditProps> = () => {
  const history = useHistory()
  const match = useRouteMatch()
  const id = match.params.id

  const classes = useStyles()

  const snack = useSnackbar()

  const [concert, setConcert] = useState<ConcertDetail | null>(null)

  const client = useAuthedApiClient()

  useEffect(() => {
    client && client.getConcert(id).then(c => setConcert(c))
  }, [client, id])

  const handleSave = async (data: PostConcertData) => {
    console.log(data)
    if (client) {
      try {
        await client.updateConcert(id, data)
        history.push(`/concerts/${id}/show`)
      } catch (e) {
        if (e instanceof ConcertDupTimeError) {
          snack.enqueueSnackbar('開催日時が重複しています')
        } else {
          snack.enqueueSnackbar('登録に失敗しました')
        }
      }
    } else {
      snack.enqueueSnackbar('更新に失敗しました')
    }
  }

  console.log('concert', concert)

  return (
    <DefaultLayout title={concert ? concert.name : ''}>
      {!concert && <LinearProgress />}
      {concert && (
        <div className={classes.root}>
          <ConcertForm
            concertName={concert.name}
            artistName={concert.artist_name}
            drawingArtistName={concert.drawing_artist_name}
            openingAt={concert.opening_at}
            closingAt={concert.closing_at}
            postClosingAt={concert.post_closing_at}
            artistNameKind={concert.artist_name_kind as ArtistNameKind}
            artistNameColor={concert.artist_name_color as ArtistNameColor}
            dateColor={concert.date_color as DateColor}
            mainImageKind={concert.main_image_kind as MainImageKind}
            mainImagePhotoFrame={
              concert.main_image_photo_frame !== null
                ? (concert.main_image_photo_frame as MainImagePhotoFrame)
                : null
            }
            artistNameLogo={concert.artist_name_logo_url}
            mainImageIllust={
              concert.main_image_kind === 1 && concert.main_image_url
                ? concert.main_image_url
                : undefined
            }
            mainImagePhoto={
              concert.main_image_kind === 2 && concert.main_image_url
                ? concert.main_image_url
                : undefined
            }
            bgKind={concert.bg_kind as BgKind}
            copyright={concert.copyright}
            onSave={handleSave}
          />
        </div>
      )}
    </DefaultLayout>
  )
}

export default ConcertEdit
