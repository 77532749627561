export const SET_USER_ACTION = 'SET_USER'
export const LOGOUT_ACTION = 'LOGOUT'

interface SetUserAction {
  type: typeof SET_USER_ACTION
  username: string
  role: string
  token: string
}

interface LogoutAction {
  type: typeof LOGOUT_ACTION
}

export type AuthAction = SetUserAction | LogoutAction

export interface AuthState {
  username: string | null
  role: string | null
  token: string | null
}

const initialState: AuthState = {
  username: null,
  role: null,
  token: null
}

const auth = (state = initialState, action: AuthAction) => {
  switch (action.type) {
    case SET_USER_ACTION:
      return {
        ...state,
        username: action.username,
        role: action.role,
        token: action.token
      }
    case LOGOUT_ACTION:
      return {
        ...state,
        token: null,
        role: null,
        username: null
      }
    default:
      return state
  }
}

export default auth
