import React, { useState, useEffect } from 'react'
import { Redirect, useLocation } from 'react-router-dom'

import { useSelector, useDispatch } from 'react-redux'

import { RootState } from './reducers'
import { AuthState } from './reducers/auth'

import { AuthedApiClient } from './lib/api_client'
import { logout } from './actions'

import CircularProgress from '@material-ui/core/CircularProgress'

const Auth: React.FC = props => {
  const auth = useSelector<RootState, AuthState>(state => state.auth)
  const dispatch = useDispatch()
  const location = useLocation()
  let origin = location.pathname
  if (origin === '/login') {
    origin = '/'
  }

  // null: 確認中。false: tokenなし
  const [valid, setValid] = useState<boolean | null>(
    auth.token !== null ? null : false
  )

  useEffect(() => {
    if (auth.token !== null) {
      const client = new AuthedApiClient(auth.token)
      client
        .getValidateToken()
        .then(() => setValid(true))
        .catch(() => {
          dispatch(logout())
          setValid(false)
        })
    }
  }, [auth.token])

  if (valid === true) {
    return <>{props.children}</>
  } else if (valid === false) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: origin }
        }}
      />
    )
  }
  return <CircularProgress />
}

export default Auth
