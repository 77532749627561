import React, { useState } from 'react'

import IconButton from '@material-ui/core/IconButton'
import AccountCircle from '@material-ui/icons/AccountCircle'

import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'

import LogoutButton from '../components/LogoutButton'
import { useOpen } from '@maruware/material-ui-hooks'
import { ChangePasswordDialog } from './ChangePasswordDialog'

interface UserMenuProps {}
const UserMenu: React.FC<UserMenuProps> = () => {
  const [anchorEl, setAnchorEl] = useState<any>(null)

  function handleClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }

  const [
    openChangePassword,
    handleOpenChangePassword,
    handleCloseChangePassword
  ] = useOpen()

  const open = Boolean(anchorEl)
  return (
    <div>
      <IconButton onClick={handleClick} aria-controls="menu-appbar">
        <AccountCircle />
      </IconButton>

      <Menu
        id="menu-appbar"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <MenuItem onClick={handleOpenChangePassword}>パスワード変更</MenuItem>
        <MenuItem>
          <LogoutButton>ログアウト</LogoutButton>
        </MenuItem>
      </Menu>

      <ChangePasswordDialog
        open={openChangePassword}
        onClose={handleCloseChangePassword}
      />
    </div>
  )
}

export default UserMenu
