import React from 'react'
import { HashRouter as Router, Switch, Route, Redirect } from 'react-router-dom'

import Auth from './Auth'
import Login from './pages/Login'

import ConcertList from './pages/Concert/ConcertList'
import ConcertShow from './pages/Concert/ConcertShow'
import ConcertEdit from './pages/Concert/ConcertEdit'
import ConcertNew from './pages/Concert/ConcertNew'
import {
  NormalUserMessageList,
  ArtistUserMessageList
} from './pages/Concert/UserMessageList'
import SnapShotList from './pages/Concert/SnapShotList'

import ConcertNgWordList from './pages/Concert/ConcertNgWordList'

import NgWordList from './pages/NgWord/NgWordList'
import AdminUserList from './pages/AdminUserList'

import SuperRoute from './components/SuperRoute'

const App: React.FC = () => {
  return (
    <Router>
      <Switch>
        <Route path="/login" component={Login} />

        <Auth>
          <Switch>
            <Route exact={true} path="/">
              <Redirect to="/concerts" />
            </Route>
            <Route exact={true} path="/concerts" component={ConcertList} />
            <SuperRoute
              exact={true}
              path="/concerts/:id/edit"
              component={ConcertEdit}
            />

            <SuperRoute
              exact={true}
              path="/concerts/new"
              component={ConcertNew}
            />
            <Route
              exact={true}
              path="/concerts/:id/show"
              component={ConcertShow}
            />
            <Route
              exact={true}
              path="/concerts/:id/user_messages"
              component={NormalUserMessageList}
            />
            <Route
              exact={true}
              path="/concerts/:id/artist_messages"
              component={ArtistUserMessageList}
            />
            <Route
              exact={true}
              path="/concerts/:id/snap_shots"
              component={SnapShotList}
            />
            <SuperRoute exact={true} path="/ng_words" component={NgWordList} />
            <SuperRoute
              exact={true}
              path="/concerts/:id/ng_words"
              component={ConcertNgWordList}
            />

            <SuperRoute
              exact={true}
              path="/admin_users"
              component={AdminUserList}
            />
          </Switch>
        </Auth>
      </Switch>
    </Router>
  )
}

export default App
