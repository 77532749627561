import React, { useState, useEffect, useRef, useCallback } from 'react'

import {
  MainImageKind,
  MainImagePhotoFrame,
  ArtistNameKind,
  ArtistNameColor,
  BgKind,
  DateColor
} from '../types'

import { nonePhotoFrame, getPhotoFrame } from './PhotoFrame'

import { makeStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import FormControlLabel from '@material-ui/core/FormControlLabel'

// import useInterval from '@use-it/interval'
import timesInterval from '../../../lib/times-interval'

import { getImageSize, Size } from '../../../lib/imaging'
import clsx from 'clsx'

import BgImageSvg from './BgImageSvg'
import BaseImageSvg from './BaseImageSvg'
import RestrictImageSvg from './RestrictImageSvg'
import OverlayImageSvg from './OverlayImageSvg'
import MaskForJointMaskImageSvg from './MaskForJointMaskImageSvg'

import {
  getArtistNameFontSetting,
  getArtistNameAndDatePos,
  XY
} from './arrangement'
import { DateTime } from 'luxon'
import GuideLineSvg from './GuideLine'

const CANVAS_WIDTH = 3840
const CANVAS_HEIGHT = 1080

const ILLUST_HEIGHT = 560
// const SVG_SCALE = 35

const DATE_FONT_SIZE = 50

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    width: '100%',
    height: '100%'
  },
  svgsContainer: {
    position: 'relative',
    width: '100%',
    height: 'auto'
  },
  svgElement: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: 'auto'
  },
  baseImageSvg: {},
  restrictImageSvg: {
    opacity: 0.5
  },
  hideRestrictImageSvg: {
    opacity: 0
  },
  maskForJointMaskImageSvg: {
    opacity: 0
  },
  switches: {
    display: 'flex'
  }
}))

interface PreviewSvgProps {
  className?: string
  artistName: string
  mainImageKind: MainImageKind
  mainImagePhotoFrame: MainImagePhotoFrame | null
  mainImageIllust: string | null
  mainImagePhoto: string | null

  bgKind: BgKind

  copyright: string | null

  artistNameKind: ArtistNameKind
  artistNameColor: ArtistNameColor
  dateColor: DateColor
  artistNameLogo: string | null

  openingAt: DateTime | null
  baseSvgRef: React.RefObject<SVGSVGElement>
  restrictSvgRef: React.RefObject<SVGSVGElement>
  maskForJointMaskSvgRef: React.RefObject<SVGSVGElement>
}

const PreviewSvg: React.FC<PreviewSvgProps> = ({
  artistName,
  mainImageKind,
  mainImageIllust,
  mainImagePhoto,
  mainImagePhotoFrame,
  bgKind,
  copyright,
  artistNameKind,
  artistNameColor,
  dateColor,
  artistNameLogo,
  openingAt,
  baseSvgRef,
  restrictSvgRef,
  maskForJointMaskSvgRef
}) => {
  const classes = useStyles()

  const artistNameTextRef = useRef<any>(null)
  const dateTextRef = useRef<any>(null)
  const copyrightRef = useRef<any>(null)

  const [showResrict, setShowRestrict] = useState(false)
  const handleChangeShowRestrict = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setShowRestrict(event.target.checked)
    },
    []
  )

  const [showGuideLine, setShowGuideLine] = useState(false)
  const handleChangeShowGuideLine = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setShowGuideLine(event.target.checked)
    },
    []
  )

  // パラメータ整理
  const [mainImageUrl, setMainImageUrl] = useState<string | null>(null)
  useEffect(() => {
    let url: string | null
    if (mainImageKind === 1 && mainImageIllust) {
      url = mainImageIllust
    } else if (mainImageKind === 2 && mainImagePhoto) {
      url = mainImagePhoto
    } else {
      url = null
    }
    setMainImageUrl(url)
  }, [mainImageKind, mainImageIllust, mainImagePhoto])

  const [dateText, setDateText] = useState<string>('')
  useEffect(() => {
    const t =
      openingAt && openingAt.isValid ? openingAt.toFormat('y/LL/dd') : ''
    setDateText(t)
  }, [openingAt])

  // サイズ系
  const [mainImageSize, setMainImageSize] = useState<Size>({
    width: 0,
    height: 0
  })
  useEffect(() => {
    if (mainImageUrl) {
      getImageSize(mainImageUrl).then(size => setMainImageSize(size))
    }
  }, [mainImageUrl])

  const [artistNameLogoImageSize, setArtistNameLogoImageSize] = useState<Size>({
    width: 0,
    height: 0
  })
  useEffect(() => {
    if (artistNameKind === ArtistNameKind.Logo && artistNameLogo) {
      getImageSize(artistNameLogo).then(size =>
        setArtistNameLogoImageSize(size)
      )
    }
  }, [artistNameKind, artistNameLogo])

  const [artistNameBBox, setArtistNameBBox] = useState(new DOMRect(0, 0, 0, 0))
  // useEffect(() => {
  //   if (artistNameTextRef.current) {
  //     const bb = artistNameTextRef.current.getBBox()
  //     setArtistNameBBox(bb)
  //   }
  // }, [artistName, artistNameTextRef.current])

  const [dateBBox, setDateBBox] = useState(new DOMRect(0, 0, 0, 0))
  // useEffect(() => {
  //   if (dateTextRef.current) {
  //     const bb = dateTextRef.current.getBBox()
  //     setDateBBox(bb)
  //   }
  // }, [dateText, dateTextRef.current])

  const [copyrightBBox, setCopyrightBBox] = useState(new DOMRect(0, 0, 0, 0))
  // useEffect(() => {
  //   if (copyrightRef.current) {
  //     const bb = copyrightRef.current.getBBox()
  //     setCopyrightBBox(bb)
  //   }
  // }, [copyright, copyrightRef.current])

  // BBoxが更新されないことがあるのでとりあえず3回くらい実行して回避する
  const start = timesInterval(3, 100, () => {
    console.log('initialize bbox')
    if (artistNameTextRef.current) {
      const bb = artistNameTextRef.current.getBBox()
      setArtistNameBBox(bb)
    }
    if (dateTextRef.current) {
      const bb = dateTextRef.current.getBBox()
      setDateBBox(bb)
    }
    if (copyrightRef.current) {
      const bb = copyrightRef.current.getBBox()
      setCopyrightBBox(bb)
    }
  })
  useEffect(() => {
    start()
  }, [
    artistName,
    artistNameTextRef.current,
    copyright,
    copyrightRef.current,
    dateText,
    dateTextRef.current
  ])

  const artistNameFontSetting = getArtistNameFontSetting(
    artistNameKind,
    artistName
  )

  let photoFrame = nonePhotoFrame
  if (mainImageKind === MainImageKind.Photo) {
    photoFrame = getPhotoFrame(mainImagePhotoFrame)
  }

  const illustSize: Size = { width: 0, height: 0 }
  if (mainImageKind === MainImageKind.Illust) {
    illustSize.width =
      (mainImageSize.width / mainImageSize.height) * ILLUST_HEIGHT
    illustSize.height = ILLUST_HEIGHT
  }

  const { artistNamePos, datePos } = getArtistNameAndDatePos(
    CANVAS_WIDTH,
    CANVAS_HEIGHT,
    mainImageKind,
    artistNameFontSetting.size,
    DATE_FONT_SIZE
  )

  const artistNameBBoxCorrect = new DOMRect(
    artistNameBBox.x,
    artistNameBBox.y,
    artistNameBBox.width,
    artistNameBBox.height
  )
  if (artistNameKind === ArtistNameKind.Ja) {
    // 日本語のときにフォントで上下に余白が載ってしまう。無理やり補正する。

    // 180: 20 = artistNameFontSize : x
    const offset = (40 * artistNameFontSetting.size) / 180
    artistNamePos.y -= offset
    artistNameBBoxCorrect.y += offset
    artistNameBBoxCorrect.height -= offset * 2
  } else if (artistNameKind === ArtistNameKind.Logo) {
    // 無理やりartist name bboxを設定する
    const w =
      (artistNameLogoImageSize.width / artistNameLogoImageSize.height) *
      artistNameFontSetting.size
    const h = artistNameFontSetting.size
    artistNameBBoxCorrect.x = -w / 2
    artistNameBBoxCorrect.y = 0
    artistNameBBoxCorrect.width = w
    artistNameBBoxCorrect.height = h
  }

  let rotation = 0
  if ([ArtistNameKind.En, ArtistNameKind.Ja].includes(artistNameKind)) {
    rotation = -6
  }

  let copyrightMaskPolygon: XY[] = []
  if (copyright) {
    copyrightMaskPolygon = [
      { x: -copyrightBBox.width / 2 - 5, y: 0 },
      { x: -copyrightBBox.width / 2 - 5 - 5, y: 22 },
      { x: copyrightBBox.width / 2 + 5, y: 22 },
      { x: copyrightBBox.width / 2 + 5 + 5, y: 0 }
    ]
  }

  // console.log('PreviewSvg')
  return (
    <div className={classes.root}>
      <div className={classes.switches}>
        <FormControlLabel
          control={
            <Switch checked={showResrict} onChange={handleChangeShowRestrict} />
          }
          label="書き込み禁止エリアを表示"
        />

        <FormControlLabel
          control={
            <Switch
              checked={showGuideLine}
              onChange={handleChangeShowGuideLine}
            />
          }
          label="ガイド表示"
        />
      </div>

      <div className={classes.svgsContainer}>
        <BgImageSvg
          className={classes.svgElement}
          width={CANVAS_WIDTH}
          height={CANVAS_HEIGHT}
          bgKind={bgKind}
        />
        <BaseImageSvg
          className={clsx(classes.svgElement, classes.baseImageSvg)}
          svgRef={baseSvgRef}
          artistNameTextRef={artistNameTextRef}
          dateTextRef={dateTextRef}
          copyrightRef={copyrightRef}
          width={CANVAS_WIDTH}
          height={CANVAS_HEIGHT}
          mainImageKind={mainImageKind}
          photoFrame={photoFrame}
          mainImagePhotoFrame={mainImagePhotoFrame}
          illustSize={illustSize}
          mainImageUrl={mainImageUrl}
          artistName={artistName}
          artistNameKind={artistNameKind}
          artistNamePos={artistNamePos}
          datePos={datePos}
          artistNameFontSize={artistNameFontSetting.size}
          artistNameLetterSpacing={artistNameFontSetting.letterSpacing}
          artistNameColor={artistNameColor}
          artistNameLogo={artistNameLogo}
          artistNameLogoImageSize={artistNameLogoImageSize}
          rotation={rotation}
          dateText={dateText}
          dateColor={dateColor}
          dateFontSize={DATE_FONT_SIZE}
          copyright={copyright}
          copyrightMaskPolygon={copyrightMaskPolygon}
        />

        <OverlayImageSvg
          className={classes.svgElement}
          width={CANVAS_WIDTH}
          height={CANVAS_HEIGHT}
        />

        <RestrictImageSvg
          className={clsx(classes.svgElement, classes.restrictImageSvg, {
            [classes.hideRestrictImageSvg]: !showResrict
          })}
          svgRef={restrictSvgRef}
          width={CANVAS_WIDTH}
          height={CANVAS_HEIGHT}
          mainImageKind={mainImageKind}
          illustSize={illustSize}
          artistNameKind={artistNameKind}
          photoFrame={photoFrame}
          artistNamePos={artistNamePos}
          datePos={datePos}
          artistNameBBox={artistNameBBoxCorrect}
          rotation={rotation}
          dateBBox={dateBBox}
          artistNameWritableMargin={artistNameFontSetting.writableMargin}
          copyrightMaskPolygon={copyrightMaskPolygon}
        />

        <MaskForJointMaskImageSvg
          className={clsx(classes.svgElement, classes.maskForJointMaskImageSvg)}
          svgRef={maskForJointMaskSvgRef}
          width={CANVAS_WIDTH}
          height={CANVAS_HEIGHT}
          mainImageKind={mainImageKind}
          photoFrame={photoFrame}
          artistNameKind={artistNameKind}
          artistNamePos={artistNamePos}
          datePos={datePos}
          dateBBox={dateBBox}
          copyrightMaskPolygon={copyrightMaskPolygon}
        />

        <GuideLineSvg
          className={clsx(classes.svgElement)}
          width={CANVAS_WIDTH}
          height={CANVAS_HEIGHT}
          show={showGuideLine}
        />
      </div>
    </div>
  )
}

export default PreviewSvg
