import React from 'react'
import { BgKind } from '../types'

interface BgImageSvgProps {
  className?: string
  width: number
  height: number
  bgKind: BgKind
}
const BgImageSvg: React.FC<BgImageSvgProps> = ({
  className,
  width,
  height,
  bgKind
}) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      style={{ backgroundColor: 'white' }}
    >
      <image
        x={0}
        y={0}
        width={width}
        height={height}
        xlinkHref={`/images/bg/${bgKind}.png`}
      />
    </svg>
  )
}

export default BgImageSvg
