import { createStore, compose } from 'redux'
import persistState from 'redux-localstorage'

import rootReducer from './reducers'

const composedEnhancers = compose(persistState(['auth']))

const initialState = {}

const store = createStore(rootReducer, initialState, composedEnhancers)

export default store
