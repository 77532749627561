import React from 'react'
import { Route, RouteProps, Redirect } from 'react-router-dom'

import _ from 'lodash'

import { useRole } from '../lib/use-role'

const SuperRoute: React.FC<RouteProps> = props => {
  const { isSuper } = useRole()

  const rest = _.omit(props, ['component'])
  return (
    <Route
      {...rest}
      render={innerProps =>
        isSuper ? (
          <Route {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: innerProps.location }
            }}
          />
        )
      }
    />
  )
}

export default SuperRoute
