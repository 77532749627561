import React, { useState, useEffect, useMemo } from 'react'

import { DateTime } from 'luxon'
import { useHistory } from 'react-router-dom'

import Calendar, { Schedule } from '../../components/Calendar'
import Button from '@material-ui/core/Button'
import LeftIcon from '@material-ui/icons/ChevronLeft'
import RightIcon from '@material-ui/icons/ChevronRight'

import Typography from '@material-ui/core/Typography'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'

import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import { ConcertSummary } from '../../lib/api_client'
import useAuthedApiClient from '../../lib/useAuthedApiClient'

import DefaultLayout from '../../layout/DefaultLayout'
import { useRole } from '../../lib/use-role'

import { useQuery } from '../../lib/use-query'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1)
  },
  headerLeft: {
    // flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(3)
  },
  calendarContainer: {
    flexGrow: 1
  },
  calendarContentContainer: {
    height: '100%'
  }
}))

interface ConcertListProps {}
const ConcertList: React.FC<ConcertListProps> = () => {
  const history = useHistory()
  const { isSuper } = useRole()

  const query = useQuery()

  const som = useMemo(() => {
    const ym = query.get('ym')
    if (ym) {
      const r = ym.split('-')
      if (r.length === 2) {
        const y = parseInt(r[0])
        const m = parseInt(r[1])
        return DateTime.local(y, m)
      }
    }
    return DateTime.local().startOf('month')
  }, [query.get('ym')])

  const client = useAuthedApiClient()

  const [concerts, setConcerts] = useState<ConcertSummary[]>([])

  useEffect(() => {
    client &&
      client
        .getConcerts(som, som.endOf('month'))
        .then(concerts => setConcerts(concerts))
  }, [client, som])

  const schedules: Schedule[] = concerts.map(concert => ({
    id: concert.hash_id,
    from: DateTime.fromISO(concert.opening_at),
    until: DateTime.fromISO(concert.closing_at),
    title: `${concert.artist_name}/${concert.name}`
  }))

  const onSelect = (schedule: Schedule) => {
    history.push(`/concerts/${schedule.id}/show`)
  }

  const handleLastMonth = () => {
    const d = som.minus({ month: 1 })
    const ym = d.toFormat('yyyy-MM')
    history.push(`/concerts?ym=${ym}`)
  }

  const handleNextMonth = () => {
    const d = som.plus({ month: 1 })
    const ym = d.toFormat('yyyy-MM')
    history.push(`/concerts?ym=${ym}`)
  }

  const goNew = () => {
    history.push({
      pathname: '/concerts/new',
      state: { ymd: som.toFormat('y-LL-dd') }
    })
  }

  const classes = useStyles()

  return (
    <DefaultLayout title="コンサート">
      <div className={classes.root}>
        <div className={classes.header}>
          <div className={classes.headerLeft}>
            <IconButton onClick={handleLastMonth}>
              <LeftIcon />
            </IconButton>
            <Typography variant="h5">
              {som.year}年{som.month}月
            </Typography>
            <IconButton onClick={handleNextMonth}>
              <RightIcon />
            </IconButton>
          </div>
          {isSuper && (
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={goNew}
            >
              コンサートを登録
            </Button>
          )}
        </div>
        <Card className={classes.calendarContainer}>
          <CardContent className={classes.calendarContentContainer}>
            <Calendar
              startOfMonth={som}
              schedules={schedules}
              onSelect={onSelect}
            />
          </CardContent>
        </Card>
      </div>
    </DefaultLayout>
  )
}

export default ConcertList
