import React, { useState, useRef } from 'react'

import TextField from '@material-ui/core/TextField'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import { makeStyles } from '@material-ui/core/styles'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormLabel from '@material-ui/core/FormLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import Switch from '@material-ui/core/Switch'

import EventIcon from '@material-ui/icons/Event'
import ImageIcon from '@material-ui/icons/Image'
import ArtistNameIcon from '@material-ui/icons/TextFormat'
import ColorIcon from '@material-ui/icons/ColorLens'

import { KeyboardDateTimePicker } from '@material-ui/pickers'

import _ from 'lodash'
import ImageUploadButton from '../../components/ImageUploadButton'
import PreviewSvg from './PreviewSvg'

import {
  ArtistNameKind,
  ArtistNameColor,
  MainImageKind,
  BgKind,
  MainImagePhotoFrame,
  DateColor
} from './types'

import { DateTime } from 'luxon'

import { delay } from '@maruware/promise-tools'

import { svgToPng, trimBase64TypePrefix } from '../../lib/imaging'
import { PostConcertData } from '../../lib/api_client'
import { ColorRectRadio } from '../../components/ColorRect'
import { ImgRadio } from '../../components/ImgRadio'
import TextColor, { textColors, toHexColor } from './TextColor'
import { useSnackbar } from 'notistack'
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  paper: {
    padding: theme.spacing(2)
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  fields: {
    display: 'flex',
    flexDirection: 'column'
  },
  row: {
    display: 'flex',
    alignItems: 'baseline'
  },
  editor: {
    display: 'flex'
  },
  editorFields: {
    width: 360
  },
  subtitle: {
    // marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    verticalAlign: 'middle',
    display: 'inline-flex'
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.spacing(2)
  },
  artistNameColorRoot: {
    flexDirection: 'row'
  },
  dateColorRoot: {
    flexDirection: 'row'
  },
  bgKindRoot: {
    flexDirection: 'row'
  },
  basicInfoTextField: {
    minWidth: 300,
    margin: theme.spacing(1)
  },
  concertNameTextField: {
    minWidth: 480
  },
  dateField: {},
  dateTimeField: {
    width: 200,
    margin: theme.spacing(1)
  },
  editorField: {
    marginBottom: theme.spacing(1)
  },
  imageUploadButton: {
    marginBottom: theme.spacing(2)
  },
  previewContainer: {
    flexGrow: 1,
    marginLeft: theme.spacing(3)
  },
  saveButton: {},
  buttonProgress: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1)
  },
  radioGroup: {
    paddingLeft: 11 // 補正
  }
}))

const artistNameRegex = /^[a-zA-Z0-9^~<=>|_\-,;!?·'’“”()[\]{}@$£€*\\&%+#¥. ]*$/

interface ConcertFormProps {
  artistName?: string
  concertName?: string
  openingAt?: string
  closingAt?: string
  postClosingAt?: string
  drawingArtistName?: string | null
  artistNameKind?: ArtistNameKind
  artistNameColor?: ArtistNameColor
  dateColor?: DateColor
  mainImageKind?: MainImageKind
  mainImagePhotoFrame?: MainImagePhotoFrame | null
  artistNameLogo?: string | null
  mainImageIllust?: string
  mainImagePhoto?: string
  bgKind?: BgKind
  copyright?: string | null
  onSave: (data: any) => Promise<any>
}
const ConcertForm: React.FC<ConcertFormProps> = props => {
  const classes = useStyles()
  const snackbar = useSnackbar()

  const [artistName, setArtistName] = useState(props.artistName || '')
  const [concertName, setConcertName] = useState(props.concertName || '')

  const [drawingArtistName, setDrawingArtistName] = useState<string | null>(
    props.drawingArtistName || null
  )

  const [openingAt, setOpeningAt] = useState<DateTime | null>(
    props.openingAt ? DateTime.fromISO(props.openingAt) : null
  )
  const [closingAt, setClosingAt] = useState<DateTime | null>(
    props.closingAt ? DateTime.fromISO(props.closingAt) : null
  )
  const [postClosingAt, setPostClosingAt] = useState<DateTime | null>(
    props.postClosingAt ? DateTime.fromISO(props.postClosingAt) : null
  )

  const [artistNameKind, setArtistNameKind] = useState<ArtistNameKind>(
    props.artistNameKind !== undefined ? props.artistNameKind : -1
  )
  const [artistNameColor, setArtistNameColor] = useState<ArtistNameColor>(
    props.artistNameColor !== undefined ? props.artistNameColor : TextColor.Red
  )
  const [dateColor, setDateColor] = useState<DateColor>(
    props.dateColor !== undefined ? props.dateColor : TextColor.White
  )
  const [mainImageKind, setMainImageKind] = useState<MainImageKind>(
    props.mainImageKind !== undefined ? props.mainImageKind : -1
  )
  const [
    mainImagePhotoFrame,
    setMainImagePhotoFrame
  ] = useState<MainImagePhotoFrame | null>(
    props.mainImagePhotoFrame !== undefined ? props.mainImagePhotoFrame : null
  )
  const [artistNameLogo, setArtistNameLogo] = useState<string | null>(
    props.artistNameLogo || null
  )
  const [mainImageIllust, setMainImageIllust] = useState<string | null>(
    props.mainImageIllust || null
  )
  const [mainImagePhoto, setMainImagePhoto] = useState<string | null>(
    props.mainImagePhoto || null
  )

  const [bgKind, setBgKind] = useState<BgKind>(
    props.bgKind !== undefined ? props.bgKind : 0
  )

  const [copyright, setCopyright] = useState<string | null>(
    props.copyright || null
  )

  const baseSvgRef = useRef<SVGSVGElement | null>(null)
  const restrictSvgRef = useRef<SVGSVGElement | null>(null)
  const maskForJointMaskSvgRef = useRef<SVGSVGElement | null>(null)

  const handleChangeArtistName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value
    if (artistNameKind === 0 && drawingArtistName === null) {
      if (val.match(artistNameRegex)) {
        setArtistName(val)
      } else {
        snackbar.enqueueSnackbar('英語タイプに使用できない文字が含まれています')
      }
    } else {
      setArtistName(val)
    }
  }

  const handleChangeConcertName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConcertName(e.target.value)
  }

  const handleChangeEnableDrawingArtistName = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setDrawingArtistName(e.target.checked ? '' : null)
  }

  const handleChangeDrawingArtistName = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const val = e.target.value
    if (artistNameKind === 0 && drawingArtistName !== null) {
      if (val.match(artistNameRegex)) {
        setDrawingArtistName(val)
      } else {
        snackbar.enqueueSnackbar('英語タイプに使用できない文字が含まれています')
      }
    } else {
      setDrawingArtistName(val)
    }
  }

  const handleChangeArtistNameKind = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    setArtistNameKind(e.target.value as ArtistNameKind)
  }

  const handleChangeArtistNameColor = (event: React.ChangeEvent<unknown>) => {
    const v = (event.target as HTMLInputElement).value
    setArtistNameColor(parseInt(v) as ArtistNameColor)
  }

  const handleChangeDateColor = (event: React.ChangeEvent<unknown>) => {
    const v = (event.target as HTMLInputElement).value
    setDateColor(parseInt(v) as DateColor)
  }

  const handleChangeMainImageKind = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const val = e.target.value as number
    if (val >= 0) {
      setMainImageKind(val as MainImageKind)
    }
  }

  const handleChangeMainImagePhotoFrame = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>
  ) => {
    const val = e.target.value as number
    if (val >= 0) {
      setMainImagePhotoFrame(val as MainImagePhotoFrame)
    }
  }

  const handleChangeArtistNameLogo = (dataUrl: string) => {
    setArtistNameLogo(dataUrl)
  }

  const handleChangeMainImageIllust = (dataUrl: string) => {
    setMainImageIllust(dataUrl)
  }

  const handleChangeMainImagePhoto = (dataUrl: string) => {
    setMainImagePhoto(dataUrl)
  }

  const handleChangeBgKind = (event: React.ChangeEvent<unknown>) => {
    const v = (event.target as HTMLInputElement).value
    setBgKind(parseInt(v) as BgKind)
  }

  const handleChangeOpeningAt = (date: DateTime | null) => {
    console.log('date', date)
    setOpeningAt(date)
    if (!closingAt || (date && closingAt < date)) {
      setClosingAt(date)
    }
    if (!postClosingAt || (date && postClosingAt < date)) {
      setPostClosingAt(date)
    }
  }

  const handleChangeCopyright = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCopyright(e.target.value)
  }

  const [saving, setSaving] = useState(false)

  let valid =
    artistName &&
    concertName &&
    openingAt &&
    closingAt &&
    artistNameKind >= 0 &&
    mainImageKind >= 0
  if (mainImageKind === 1 && !mainImageIllust) {
    valid = false
  }
  if (mainImageKind === 2 && !mainImagePhoto) {
    valid = false
  }
  if (artistNameKind === 2 && !artistNameLogo) {
    valid = false
  }

  const handleSave = async () => {
    if (
      !baseSvgRef.current ||
      !restrictSvgRef.current ||
      !maskForJointMaskSvgRef.current
    ) {
      return
    }

    setSaving(true)
    await delay(20)

    const [baseImage, restrictImage, maskForJointMaskImage] = await Promise.all(
      [
        svgToPng(baseSvgRef.current),
        svgToPng(restrictSvgRef.current),
        svgToPng(maskForJointMaskSvgRef.current)
      ]
    )

    let mainImage: string | null = null
    if (mainImageKind === 1 && mainImageIllust !== props.mainImageIllust) {
      mainImage = mainImageIllust
    } else if (mainImageKind === 2 && mainImagePhoto !== props.mainImagePhoto) {
      mainImage = mainImagePhoto
    }
    if (mainImage) {
      mainImage = trimBase64TypePrefix(mainImage)
    }

    let b64ArtistNameLogo: string | null = null
    if (artistNameLogo && artistNameLogo !== props.artistNameLogo) {
      b64ArtistNameLogo = trimBase64TypePrefix(artistNameLogo)
    }

    if (!openingAt || !closingAt || !postClosingAt) {
      return
    }
    const data: PostConcertData = {
      name: concertName,
      artist_name: artistName,
      drawing_artist_name: drawingArtistName,
      opening_at: openingAt.toISO(),
      closing_at: closingAt.toISO(),
      post_closing_at: postClosingAt.toISO(),
      artist_name_kind: artistNameKind,
      artist_name_color: artistNameColor,
      date_color: dateColor,
      main_image_kind: mainImageKind,
      main_image_photo_frame: mainImagePhotoFrame,
      base64_main_image: mainImage,
      base64_artist_name_logo: b64ArtistNameLogo,
      bg_kind: bgKind,
      copyright: copyright,
      base64_base_image: trimBase64TypePrefix(baseImage),
      base64_restrict_image: trimBase64TypePrefix(restrictImage),
      base64_mask_for_joint_mask_image: trimBase64TypePrefix(
        maskForJointMaskImage
      )
    }

    try {
      await props.onSave(data)
    } catch {
      // no ops
    }
    setSaving(false)
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <div className={classes.fields}>
          <div className={classes.section}>
            <Typography variant="subtitle1" className={classes.subtitle}>
              <EventIcon />
              基本情報
            </Typography>
            <div className={classes.row}>
              <TextField
                label="アーティスト名"
                className={classes.basicInfoTextField}
                value={artistName}
                onChange={handleChangeArtistName}
              />
              <TextField
                label="コンサート名"
                className={clsx(
                  classes.basicInfoTextField,
                  classes.concertNameTextField
                )}
                value={concertName}
                onChange={handleChangeConcertName}
              />
            </div>
            <div className={classes.row}>
              <KeyboardDateTimePicker
                variant="inline"
                ampm={false}
                label="スクリーン表示日時"
                value={openingAt}
                onChange={handleChangeOpeningAt}
                format="yyyy/LL/dd HH:mm"
                className={classes.dateTimeField}
              />
              〜
              <KeyboardDateTimePicker
                variant="inline"
                ampm={false}
                value={closingAt}
                onChange={setClosingAt}
                format="yyyy/LL/dd HH:mm"
                className={classes.dateTimeField}
              />
              <KeyboardDateTimePicker
                variant="inline"
                label="投稿終了日時"
                ampm={false}
                value={postClosingAt}
                onChange={setPostClosingAt}
                format="yyyy/LL/dd HH:mm"
                className={classes.dateTimeField}
              />
            </div>
          </div>

          <Divider className={classes.divider} />

          <div className={classes.editor}>
            <div className={clsx(classes.fields, classes.editorFields)}>
              <div className={classes.section}>
                <Typography variant="subtitle1" className={classes.subtitle}>
                  <ArtistNameIcon />
                  アーティスト名
                </Typography>

                <FormControlLabel
                  control={
                    <Switch
                      checked={drawingArtistName !== null}
                      onChange={handleChangeEnableDrawingArtistName}
                      color="primary"
                    />
                  }
                  label="別名を使用する"
                />

                {drawingArtistName !== null && (
                  <TextField
                    label="描画用アーティスト名"
                    value={drawingArtistName}
                    onChange={handleChangeDrawingArtistName}
                    className={classes.editorField}
                  />
                )}

                <FormControl className={classes.editorField}>
                  <InputLabel htmlFor="artist-name-kind">
                    アーティスト名のタイプ
                  </InputLabel>
                  <Select
                    displayEmpty
                    value={artistNameKind}
                    onChange={handleChangeArtistNameKind}
                    inputProps={{ id: 'artist-name-kind' }}
                  >
                    <MenuItem
                      value={ArtistNameKind.En}
                      disabled={
                        !(drawingArtistName !== null
                          ? drawingArtistName
                          : artistName
                        ).match(artistNameRegex)
                      }
                    >
                      英語
                    </MenuItem>
                    <MenuItem value={ArtistNameKind.Ja}>日本語</MenuItem>
                    <MenuItem value={ArtistNameKind.Logo}>ロゴ画像</MenuItem>
                  </Select>
                </FormControl>
                {/* テキスト系の場合は色を選べる */}
                {(artistNameKind === ArtistNameKind.En ||
                  artistNameKind === ArtistNameKind.Ja) && (
                  <FormControl
                    component="fieldset"
                    className={classes.editorField}
                  >
                    <RadioGroup
                      name="artist-name-color"
                      className={classes.radioGroup}
                      value={artistNameColor.toString()}
                      onChange={handleChangeArtistNameColor}
                      classes={{
                        root: classes.artistNameColorRoot
                      }}
                    >
                      {textColors.map((c, i) => (
                        <FormControlLabel
                          key={i}
                          value={c.toString()}
                          control={
                            <ColorRectRadio
                              rectColor={toHexColor(c)}
                              width={24}
                              height={24}
                            />
                          }
                          label={<span />}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                )}
                {artistNameKind === ArtistNameKind.Logo && (
                  <ImageUploadButton
                    className={classes.imageUploadButton}
                    value={artistNameLogo}
                    onChange={handleChangeArtistNameLogo}
                  >
                    ロゴ画像ファイル選択
                  </ImageUploadButton>
                )}
              </div>
              <div className={classes.section}>
                <Typography variant="subtitle1" className={classes.subtitle}>
                  <ImageIcon />
                  メイン画像
                </Typography>

                <FormControl className={classes.editorField}>
                  <InputLabel htmlFor="artist-name-kind">
                    メイン画像のタイプ
                  </InputLabel>
                  <Select
                    value={mainImageKind}
                    onChange={handleChangeMainImageKind}
                    inputProps={{
                      id: 'main-image-kind'
                    }}
                  >
                    <MenuItem value={MainImageKind.None}>なし</MenuItem>
                    <MenuItem value={MainImageKind.Illust}>
                      イラスト画像
                    </MenuItem>
                    <MenuItem value={MainImageKind.Photo}>
                      アーティスト写真
                    </MenuItem>
                  </Select>
                </FormControl>

                {mainImageKind === MainImageKind.Illust && (
                  <ImageUploadButton
                    className={classes.imageUploadButton}
                    value={mainImageIllust}
                    onChange={handleChangeMainImageIllust}
                  >
                    イラスト画像を選択
                  </ImageUploadButton>
                )}
                {mainImageKind === MainImageKind.Photo && (
                  <>
                    <FormControl className={classes.editorField}>
                      <InputLabel htmlFor="main-image-photo-frame">
                        フレームタイプ
                      </InputLabel>
                      <Select
                        value={mainImagePhotoFrame}
                        onChange={handleChangeMainImagePhotoFrame}
                        inputProps={{
                          id: 'main-image-photo-frame'
                        }}
                      >
                        <MenuItem value={MainImagePhotoFrame.Landscape}>
                          ランドスケープ(662x470)
                        </MenuItem>
                        <MenuItem value={MainImagePhotoFrame.Square}>
                          スクエア(500x500)
                        </MenuItem>
                        <MenuItem value={MainImagePhotoFrame.Portrait}>
                          ポートレート(372x524)
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <ImageUploadButton
                      className={classes.imageUploadButton}
                      value={mainImagePhoto}
                      onChange={handleChangeMainImagePhoto}
                    >
                      アーティスト写真を選択
                    </ImageUploadButton>
                  </>
                )}
              </div>
              <div className={classes.section}>
                <Typography variant="subtitle1" className={classes.subtitle}>
                  <ColorIcon />
                  その他
                </Typography>

                <FormControl
                  component="fieldset"
                  className={classes.editorField}
                >
                  <FormLabel component="legend">背景</FormLabel>
                  <RadioGroup
                    name="bg-kind"
                    value={bgKind.toString()}
                    onChange={handleChangeBgKind}
                    className={classes.radioGroup}
                    classes={{
                      root: classes.bgKindRoot
                    }}
                  >
                    {_.times(9, i => (
                      <FormControlLabel
                        key={i}
                        value={i.toString()}
                        control={
                          <ImgRadio src={`/images/bg/${i}-sample.png`} />
                        }
                        label={<span />}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>

                <FormControl
                  component="fieldset"
                  className={classes.editorField}
                >
                  <FormLabel component="legend">日付の色</FormLabel>
                  <RadioGroup
                    name="daate-color"
                    className={classes.radioGroup}
                    value={dateColor.toString()}
                    onChange={handleChangeDateColor}
                    classes={{
                      root: classes.dateColorRoot
                    }}
                  >
                    {[TextColor.White, TextColor.Black].map((c, i) => (
                      <FormControlLabel
                        key={i}
                        value={c.toString()}
                        control={
                          <ColorRectRadio
                            rectColor={toHexColor(c)}
                            width={24}
                            height={24}
                          />
                        }
                        label={<span />}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>

                <TextField
                  label="コピーライト"
                  className={classes.basicInfoTextField}
                  value={copyright}
                  onChange={handleChangeCopyright}
                />
              </div>

              <Button
                color="secondary"
                variant="contained"
                size="large"
                className={classes.saveButton}
                disabled={!valid || saving}
                onClick={handleSave}
              >
                {saving && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
                保存
              </Button>
            </div>

            <div className={classes.previewContainer}>
              <PreviewSvg
                baseSvgRef={baseSvgRef}
                restrictSvgRef={restrictSvgRef}
                maskForJointMaskSvgRef={maskForJointMaskSvgRef}
                artistName={
                  drawingArtistName !== null ? drawingArtistName : artistName
                }
                mainImageKind={mainImageKind}
                mainImagePhotoFrame={mainImagePhotoFrame}
                mainImageIllust={mainImageIllust}
                mainImagePhoto={mainImagePhoto}
                bgKind={bgKind}
                artistNameKind={artistNameKind}
                artistNameColor={artistNameColor}
                dateColor={dateColor}
                artistNameLogo={artistNameLogo}
                openingAt={openingAt}
                copyright={copyright}
              />
            </div>
          </div>
        </div>
      </Paper>
    </div>
  )
}

export default ConcertForm
