import React, { useState, useEffect } from 'react'

import DefaultLayout from '../../../layout/DefaultLayout'
import useAuthedApiClient from '../../../lib/useAuthedApiClient'
import { NgWord, ConcertDetail } from '../../../lib/api_client'
import { Table } from '@devexpress/dx-react-grid-material-ui'

import { Link as RouterLink, useRouteMatch } from 'react-router-dom'

import { Card, CardContent } from '@material-ui/core'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'
import SearchIcon from '@material-ui/icons/Search'

import PaginateGrid, {
  PageQueryParams,
  Filter
} from '../../../components/PaginateGrid'

import AddDialog from './AddDialog'
import { useSnackbar } from 'notistack'
import {
  DataTypeProvider,
  DataTypeProviderProps
} from '@devexpress/dx-react-grid'
import { useTextField } from '@maruware/material-ui-hooks'

import { WordTypeProvider } from '../../NgWord/NgWordList'

const useStyles = makeStyles(theme => ({
  breadcrumbs: {
    paddingBottom: theme.spacing(1)
  },
  head: {
    display: 'flex',
    marginBottom: theme.spacing(1)
  },
  headLeft: {
    display: 'flex',
    alignItems: 'baseline',
    flexGrow: 1
  },
  headRight: {}
}))

const DELETE_ACTION = 'delete'
const ActionFormatter: React.FC<DataTypeProvider.ValueFormatterProps> = ({
  value,
  row
}) => {
  const { handler } = value
  const handleClick = () => {
    handler(DELETE_ACTION, row)
  }
  return (
    <Button color="secondary" variant="outlined" onClick={handleClick}>
      削除
    </Button>
  )
}

const ActionTypeProvider: React.FC<DataTypeProviderProps> = props => (
  <DataTypeProvider formatterComponent={ActionFormatter} {...props} />
)

interface Row extends NgWord {
  action: {
    handler: (name: string, row: Row) => void
  }
}

interface ConcertNgWordListProps {}
const ConcertNgWordList: React.FC<ConcertNgWordListProps> = () => {
  const classes = useStyles()

  const match = useRouteMatch()
  const concertId = match.params.id

  const snackbar = useSnackbar()

  const [columns] = useState([
    { name: 'id', title: 'ID' },
    { name: 'word', title: 'ワード' },
    { name: 'action', title: 'アクション' }
  ])

  const [rows, setRows] = useState<Row[]>([])
  const [totalCount, setTotalCount] = useState(0)
  const [pageQuery, setPageQuery] = useState<PageQueryParams | null>(null)

  const [loading, setLoading] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)

  const client = useAuthedApiClient()

  const [concert, setConcert] = useState<ConcertDetail | null>(null)

  useEffect(() => {
    client && client.getConcert(concertId).then(c => setConcert(c))
  }, [client, concertId])

  const handleAction = async (name: string, row: Row) => {
    if (name === DELETE_ACTION) {
      if (client) {
        try {
          await client.deleteConcertNgWord(concertId, row.id)
          await loadData()
        } catch {
          snackbar.enqueueSnackbar('削除に失敗しました')
        }
      } else {
        snackbar.enqueueSnackbar('削除に失敗しました')
      }
    }
  }

  const [search, handleChangeSearch] = useTextField('')
  const [filter, setFilter] = useState<Filter[]>()
  const handleApplyFilter = () => {
    const f: Filter = {
      op: 'contains',
      column: 'word',
      values: [search]
    }
    setFilter([f])
  }

  const loadData = async () => {
    if (client != null && !loading) {
      try {
        setLoading(true)
        const data = await client.getConcertNgWords(concertId, { ...pageQuery })
        setRows(
          data.ng_words.map(nw => ({
            ...nw,
            action: { handler: handleAction }
          }))
        )
        setTotalCount(data.total)
      } catch {
        snackbar.enqueueSnackbar('データの取得に失敗しました')
      } finally {
        setLoading(false)
      }
    }
  }
  useEffect(() => {
    loadData()
  }, [client, pageQuery])

  const handleClickAddButton = () => {
    setDialogOpen(true)
  }

  const handleCloseDialog = () => {
    setDialogOpen(false)
  }

  const handleAddNgWord = async (word: string) => {
    if (client) {
      try {
        await client.postConcertNgWord(concertId, word)
        setDialogOpen(false)
        await loadData()
      } catch {
        snackbar.enqueueSnackbar('登録に失敗しました')
      }
    } else {
      snackbar.enqueueSnackbar('登録に失敗しました')
    }
  }

  return (
    <DefaultLayout title="NGワード">
      <Breadcrumbs className={classes.breadcrumbs}>
        <Link component={RouterLink} to="/">
          コンサート
        </Link>
        <Link component={RouterLink} to={`/concerts/${concertId}/show`}>
          {concert && concert.name}
        </Link>
        <Typography color="textPrimary">NGワード</Typography>
      </Breadcrumbs>

      <div className={classes.head}>
        <div className={classes.headLeft}>
          <TextField
            label="検索"
            value={search}
            onChange={handleChangeSearch}
          />
          <Button variant="contained" onClick={handleApplyFilter}>
            <SearchIcon />
          </Button>
        </div>
        <div className={classes.headRight}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickAddButton}
          >
            追加
          </Button>
        </div>
      </div>

      <Card>
        <CardContent>
          <PaginateGrid
            rows={rows}
            columns={columns}
            filter={filter}
            totalCount={totalCount}
            onChangePage={setPageQuery}
          >
            <ActionTypeProvider for={['action']} />

            <Table />

            <WordTypeProvider for={['word']} />
          </PaginateGrid>
        </CardContent>
      </Card>

      <AddDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        onSave={handleAddNgWord}
      />
    </DefaultLayout>
  )
}
export default ConcertNgWordList
