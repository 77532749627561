import React, { useState, useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import DefaultLayout from '../../layout/DefaultLayout'
import useAuthedApiClient from '../../lib/useAuthedApiClient'
import { NgWord } from '../../lib/api_client'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { Table as DxTable } from '@devexpress/dx-react-grid-material-ui'

import { makeStyles } from '@material-ui/core/styles'
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core'

import Papa from 'papaparse'
import Encoding from 'encoding-japanese'
import download from 'downloadjs'

import PaginateGrid, { PageQueryParams } from '../../components/PaginateGrid'
import { useSnackbar } from 'notistack'
import {
  DataTypeProvider,
  DataTypeProviderProps,
  Sorting
} from '@devexpress/dx-react-grid'

const useStyles = makeStyles(theme => ({
  root: {},
  head: {
    margin: theme.spacing(1)
  },
  content: {
    margin: theme.spacing(1)
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  candidatesContainer: {
    minWidth: 400
  }
}))

export const WordTypeFormatter: React.FC<
  DataTypeProvider.ValueFormatterProps
> = ({ value }) => {
  return <pre style={{ fontSize: 14 }}>{value}</pre>
}
export const WordTypeProvider: React.FC<DataTypeProviderProps> = props => (
  <DataTypeProvider formatterComponent={WordTypeFormatter} {...props} />
)

const WordsTable: React.FC<{ words: string[] }> = ({ words }) => {
  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>word</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {words.map((word, i) => (
          <TableRow key={i}>
            <TableCell>
              <pre style={{ fontSize: 14 }}>{word}</pre>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

interface NgWordListProps {}
const NgWordList: React.FC<NgWordListProps> = () => {
  const classes = useStyles()
  const [columns] = useState([
    { name: 'id', title: 'ID' },
    { name: 'word', title: 'ワード' }
  ])
  const [defaultSorting] = useState<Sorting>({
    columnName: 'id',
    direction: 'asc'
  })

  const [rows, setRows] = useState<NgWord[]>([])
  const [totalCount, setTotalCount] = useState(0)
  const [pageQuery, setPageQuery] = useState<PageQueryParams | null>(null)

  const [candidates, setCandidates] = useState<string[]>([])
  const [importing, setImporting] = useState(false)

  const [loading, setLoading] = useState(false)

  const history = useHistory()

  const client = useAuthedApiClient()

  const loadData = async () => {
    if (client != null && !loading) {
      try {
        setLoading(true)
        const data = await client.getNgWords({ ...pageQuery })
        setRows(data.ng_words)
        setTotalCount(data.total)
      } finally {
        setLoading(false)
      }
    }
  }

  const handleExport = async () => {
    if (client) {
      const data = await client.getNgWords({ range: [0, totalCount] })
      const csv = data.ng_words.reduce((e, word) => e + `${word.word}\r\n`, '')
      const b = Encoding.convert(csv, { type: 'string', to: 'SJIS' }) as string
      download(b, 'ng_words.csv', 'text/csv')
    }
  }
  useEffect(() => {
    loadData()
  }, [client, pageQuery])

  const snackbar = useSnackbar()

  const handleStartImporting = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      snackbar.enqueueSnackbar('ファイルの読み込みに失敗しました')
      return
    }

    const file = e.target.files[0]
    if (!file) {
      snackbar.enqueueSnackbar('ファイルの読み込みに失敗しました')
      return
    }

    setImporting(true)

    const reader = new FileReader()
    reader.onload = () => {
      const textOrg = reader.result as ArrayBuffer
      const text = Encoding.convert(new Uint8Array(textOrg), {
        to: 'UNICODE',
        from: 'AUTO',
        type: 'string'
      }) as string
      const csv = Papa.parse(text)
      const words = csv.data.map(row => row[0])
      setCandidates(words)
    }
    reader.onerror = () => {
      snackbar.enqueueSnackbar('ファイルがテキストではありません')
    }
    reader.readAsArrayBuffer(file)
    e.target.value = ''
  }

  const handleCloseImporting = () => {
    setImporting(false)
    setCandidates([])
  }

  const handleImport = async () => {
    if (candidates && client) {
      await client.importNgWords(candidates)
    }
    history.push('/')
    history.push('/ng_words')
  }
  return (
    <DefaultLayout title="NGワード">
      <div className={classes.root}>
        <div className={classes.head}>
          <Button
            variant="contained"
            color="primary"
            component="label"
            size="large"
            className={classes.importButton}
          >
            <input
              onChange={handleStartImporting}
              style={{ display: 'none' }}
              type="file"
              accept="text/csv"
            />
            CSVインポート
          </Button>

          <Button
            variant="outlined"
            color="primary"
            size="large"
            onClick={handleExport}
          >
            CSVエクスポート
          </Button>
        </div>
        <div className={classes.content}>
          <Card>
            <CardContent>
              <PaginateGrid
                defaultSorting={defaultSorting}
                rows={rows}
                columns={columns}
                totalCount={totalCount}
                onChangePage={setPageQuery}
              >
                <DxTable />

                <WordTypeProvider for={['word']} />
              </PaginateGrid>
            </CardContent>
          </Card>
        </div>
      </div>

      <Dialog open={importing} onClose={handleCloseImporting}>
        <DialogTitle>新しいNGワード</DialogTitle>
        <DialogContent className={classes.candidatesContainer}>
          {candidates.length === 0 && <span>loading...</span>}
          {candidates.length > 0 && <WordsTable words={candidates} />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseImporting}>キャンセル</Button>
          <Button variant="contained" color="secondary" onClick={handleImport}>
            インポートする
          </Button>
        </DialogActions>
      </Dialog>
    </DefaultLayout>
  )
}
export default NgWordList
