import React from 'react'

import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import PlaceholderImage from './PlaceholderImage'

const useStyles = makeStyles(theme => ({
  textContainer: {
    paddingTop: 0
  },
  text: {
    fontSize: 16
  },
  image: {
    width: 160,
    height: 120,
    objectFit: 'contain',
    backgroundColor: theme.palette.grey[300]
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  imageContainer: {
    marginBottom: theme.spacing(1)
  }
}))

interface ImageUploadButtonProps {
  className?: string
  value: string | null
  onChange: (url: string) => void
}
const ImageUploadButton: React.FC<ImageUploadButtonProps> = ({
  className,
  value,
  onChange,
  children
}) => {
  const classes = useStyles()

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return
    }

    const file = e.target.files[0]
    if (!file || !file.type.match('image.*')) {
      return
    }
    const reader = new FileReader()
    reader.onload = () => {
      const dataUrl = reader.result as string
      onChange(dataUrl)
    }
    reader.readAsDataURL(file)
  }
  return (
    <div className={className}>
      <div className={classes.imageContainer}>
        {value && <img src={value} className={classes.image} />}
        {!value && <PlaceholderImage width={200} height={150} />}
      </div>

      <Button variant="outlined" component="label">
        <PhotoLibraryIcon className={classes.buttonIcon} />
        {children}
        <input
          onChange={handleFileSelect}
          style={{ display: 'none' }}
          type="file"
          accept="image/*"
        />
      </Button>
    </div>
  )
}

export default ImageUploadButton
