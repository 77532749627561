/* eslint-disable no-redeclare */
const map = {
  0: '#ff265a', // red
  1: '#f87938', // orange
  2: '#fffb08', // yellow
  3: '#6ee358', // green
  4: '#42bcf9', // blue
  5: '#ff8bda', // pink
  6: '#994fff', // purple
  7: '#ffffff', // white
  8: '#353535' // black
}

enum TextColor {
  Red = 0,
  Orange,
  Yellow,
  Green,
  Blue,
  Pink,
  Purple,
  White,
  Black
}

export default TextColor

export const toHexColor = (c: TextColor) => {
  return map[c]
}

export const textColors = [
  TextColor.Red,
  TextColor.Orange,
  TextColor.Yellow,
  TextColor.Green,
  TextColor.Blue,
  TextColor.Pink,
  TextColor.Purple,
  TextColor.White,
  TextColor.Black
]
