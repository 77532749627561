import { useState, useEffect } from 'react'
import axios from 'axios'

const blobToBase64 = (blob: Blob) => {
  const reader = new FileReader()
  return new Promise<string>(resolve => {
    reader.onload = (e: any) => {
      resolve(e.target.result)
    }
    reader.readAsDataURL(blob)
  })
}

export function useFetchBase64(
  input: RequestInfo | null | (() => RequestInfo | null),
  deps?: readonly any[],
  init?: RequestInit
) {
  const [dataUrl, setDataUrl] = useState<string | null>(null)
  useEffect(() => {
    let inp: RequestInfo | null
    if (typeof input === 'function') {
      inp = input()
    } else {
      inp = input
    }
    if (inp) {
      fetch(inp, init)
        .then(res => res.blob())
        .then(blob => blobToBase64(blob))
        .then(d => setDataUrl(d))
    } else {
      setDataUrl(null)
    }
  }, deps)

  return dataUrl
}

export function useFetchBase64Axios(url: string, deps?: readonly any[]) {
  const [dataUrl, setDataUrl] = useState<string>('')
  useEffect(() => {
    if (url) {
      axios(url, { responseType: 'blob' })
        .then(res => res.data as Blob)
        .then(blob => blobToBase64(blob))
        .then(d => setDataUrl(d))
    } else {
      setDataUrl('')
    }
  }, deps)

  return dataUrl
}

export function useFetchBase64Static(
  input: RequestInfo,
  deps?: readonly any[],
  init?: RequestInit
) {
  const [dataUrl, setDataUrl] = useState<string>('')
  useEffect(() => {
    if (input) {
      fetch(input, init)
        .then(res => res.blob())
        .then(blob => blobToBase64(blob))
        .then(d => setDataUrl(d))
    } else {
      setDataUrl('')
    }
  }, deps)

  return dataUrl
}
