import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import BaseField from './BaseField'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  value: {
    fontSize: '14pt'
  }
}))
interface TextFieldProps {
  name: string
  value: any
}
const TextField: React.FC<TextFieldProps> = ({ name, value }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <BaseField name={name}>
        <span className={classes.value}>{value}</span>
      </BaseField>
    </div>
  )
}

export default TextField
