import React from 'react'
import clsx from 'clsx'
import Radio, { RadioProps } from '@material-ui/core/Radio'

import { makeStyles, createStyles } from '@material-ui/core/styles'
const useStylesRect = makeStyles(theme =>
  createStyles({
    root: {
      padding: 2,
      display: 'flex',
      alignItems: 'center'
    },
    checked: {
      borderWidth: 2,
      borderStyle: 'solid',
      borderColor: theme.palette.secondary.main,
      borderRadius: 3
    },
    inter: {
      width: '100%',
      height: '100%',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: theme.palette.grey[400],
      borderRadius: 3
    }
  })
)

interface ColorRectProps {
  rectColor: string
  width: number
  height: number
  checked: boolean
}

export const ColorRect: React.FC<ColorRectProps> = ({
  rectColor,
  width,
  height,
  checked
}) => {
  const classes = useStylesRect({})
  return (
    <div
      style={{ width, height }}
      className={clsx(classes.root, { [classes.checked]: checked })}
    >
      <div
        className={classes.inter}
        style={{
          backgroundColor: rectColor
        }}
      ></div>
    </div>
  )
}

const useStyles = makeStyles(() =>
  createStyles({
    radio: {
      padding: 4
    }
  })
)

export const ColorRectRadio: React.FC<
  Omit<ColorRectProps, 'checked'> & RadioProps
> = ({ rectColor, width, height, ...rest }) => {
  const classes = useStyles({})
  return (
    <Radio
      className={classes.radio}
      disableRipple
      color="default"
      checkedIcon={
        <ColorRect
          rectColor={rectColor}
          width={width}
          height={height}
          checked={true}
        />
      }
      icon={
        <ColorRect
          rectColor={rectColor}
          width={width}
          height={height}
          checked={false}
        />
      }
      {...rest}
    />
  )
}
