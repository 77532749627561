import React from 'react'
import clsx from 'clsx'

import {
  createStyles,
  makeStyles,
  useTheme,
  Theme
} from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import List from '@material-ui/core/List'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import EventIcon from '@material-ui/icons/Event'
import SecurityIcon from '@material-ui/icons/Security'
import AdminIcon from '@material-ui/icons/People'

import { useHistory, useLocation } from 'react-router-dom'

import UserMenu from './UserMenu'
import { useOpen } from '@maruware/material-ui-hooks'
import { useRole } from '../lib/use-role'

const drawerWidth = 240

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      height: '100%'
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      backgroundColor: theme.palette.common.white,
      color: theme.palette.text.primary
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    menuButton: {
      marginRight: 36
    },
    hide: {
      display: 'none'
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap'
    },
    drawerOpen: {
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    drawerClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9) + 1
      }
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',
      ...theme.mixins.toolbar
    },
    fillSpace: {
      // height: 64
    },
    main: {
      overflow: 'auto',
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column'
      // padding: theme.spacing(1)
    },
    content: {
      padding: theme.spacing(1),
      paddingTop: 64 + theme.spacing(1),
      flexGrow: 1
    },
    logo: {
      height: 28,
      marginRight: theme.spacing(3)
    },
    title: {
      flexGrow: 1
    },
    activeMenu: {
      color: theme.palette.primary.main
    }
  })
)

export interface DefaultLayoutProps {
  title: string
}
const DefaultLayout: React.FC<DefaultLayoutProps> = ({ title, children }) => {
  const { isSuper } = useRole()
  const [open, handleDrawerOpen, handleDrawerClose] = useOpen()

  const history = useHistory()
  const location = useLocation()

  function handlePush(path: string) {
    return () => {
      history.push(path)
    }
  }

  const menues = [{ name: 'コンサート', path: '/concerts', icon: EventIcon }]
  if (isSuper) {
    menues.push({ name: 'NGワード', path: '/ng_words', icon: SecurityIcon })
    menues.push({ name: 'アカウント', path: '/admin_users', icon: AdminIcon })
  }

  // { name: '管理者', path: '/admin_users', icon: PersonIcon }

  const theme = useTheme()
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}
        elevation={0}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open
            })}
          >
            <MenuIcon />
          </IconButton>

          <img src="/logo.png" className={classes.logo} />
          <Typography variant="h6" noWrap className={classes.title}>
            {title}
          </Typography>

          <UserMenu />
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })
        }}
        open={open}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </div>
        <Divider />

        <List>
          {menues.map(menu => {
            const isActive = location.pathname.startsWith(menu.path)
            return (
              <ListItem
                key={menu.path}
                button
                onClick={handlePush(menu.path)}
                className={isActive ? classes.activeMenu : ''}
              >
                <ListItemIcon>
                  <menu.icon color={isActive ? 'primary' : 'inherit'} />
                </ListItemIcon>
                <ListItemText primary={menu.name} />
              </ListItem>
            )
          })}
        </List>
      </Drawer>
      <main className={classes.main}>
        <div className={classes.fillSpace} />
        <div className={classes.content}>{children}</div>
      </main>
    </div>
  )
}

export default DefaultLayout
