import React from 'react'

import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'

import { AdminUser } from '../../lib/api_client'

export interface DeleteAdminUserConfirmProps extends Omit<DialogProps, 'open'> {
  adminUser?: AdminUser
  onExecute: () => void
  onClose: () => void
}

export const DeleteAdminUserConfirm: React.FC<DeleteAdminUserConfirmProps> = ({
  onExecute,
  adminUser,
  ...rest
}) => {
  return (
    <Dialog {...rest} open={!!adminUser}>
      <DialogContent>
        {adminUser && (
          <span>アカウント {adminUser.username}を本当に削除しますか？</span>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={rest.onClose}>キャンセル</Button>
        <Button onClick={onExecute} color="secondary" variant="contained">
          削除する
        </Button>
      </DialogActions>
    </Dialog>
  )
}
