import React from 'react'

interface OverlayImageSvgProps {
  className?: string
  width: number
  height: number
}
const OverlayImageSvg: React.FC<OverlayImageSvgProps> = ({
  className,
  width,
  height
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox={`0 0 ${width} ${height}`}
    >
      <image x="0" y="0" xlinkHref="/images/overlay/qr-board.png" />
    </svg>
  )
}

export default OverlayImageSvg
