import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'

import { Provider } from 'react-redux'

import { ThemeProvider } from '@material-ui/styles'

import { SnackbarProvider } from 'notistack'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import CustomLuxonUtils from './lib/CustomLuxonUtils'

import './index.css'
import theme from './theme'

import store from './store'

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <MuiPickersUtilsProvider utils={CustomLuxonUtils}>
          <App />
        </MuiPickersUtilsProvider>
      </SnackbarProvider>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
