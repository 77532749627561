import { Size, Rect } from '../../../lib/imaging'
import { MainImagePhotoFrame } from '../types'

import { cloneDeep } from 'lodash'

export interface PhotoFrame {
  out: Rect
  in: Size
  restrict: Rect
}

export const nonePhotoFrame: PhotoFrame = {
  out: {
    x: 0,
    y: 0,
    width: 0,
    height: 0
  },
  restrict: {
    x: 0,
    y: 0,
    width: 0,
    height: 0
  },
  in: { width: 0, height: 0 }
}

export const portraitPhotoFrame: PhotoFrame = {
  out: {
    x: 1731,
    y: 133,
    width: 387,
    height: 538
  },
  restrict: {
    x: 1731,
    y: 133,
    width: 387,
    height: 620
  },
  in: { width: 372, height: 524 }
}

export const landscapePhotoFrame: PhotoFrame = {
  out: {
    x: 1584,
    y: 159,
    width: 680,
    height: 488
  },
  restrict: {
    x: 1584,
    y: 159,
    width: 680,
    height: 600
  },
  in: { width: 662, height: 470 }
}

export const squarePhotoFrame: PhotoFrame = {
  out: {
    x: 1666,
    y: 145,
    width: 517,
    height: 516
  },
  restrict: {
    x: 1666,
    y: 145,
    width: 517,
    height: 610
  },
  in: { width: 500, height: 500 }
}

export const getPhotoFrame = (
  mainImagePhotoFrame: MainImagePhotoFrame | null
) => {
  let photoFrame = nonePhotoFrame
  if (mainImagePhotoFrame === MainImagePhotoFrame.Portrait) {
    photoFrame = portraitPhotoFrame
  } else if (mainImagePhotoFrame === MainImagePhotoFrame.Square) {
    photoFrame = squarePhotoFrame
  } else if (mainImagePhotoFrame === MainImagePhotoFrame.Landscape) {
    photoFrame = landscapePhotoFrame
  }

  return cloneDeep(photoFrame)
}

export const getForeImagePath = (
  mainImagePhotoFrame: MainImagePhotoFrame | null
) => {
  const base = '/images/photo/'
  if (mainImagePhotoFrame === MainImagePhotoFrame.Portrait) {
    return base + 'portrait-front.png'
  } else if (mainImagePhotoFrame === MainImagePhotoFrame.Square) {
    return base + 'square-front.png'
  } else if (mainImagePhotoFrame === MainImagePhotoFrame.Landscape) {
    return base + 'landscape-front.png'
  }
  return null
}

export const getBackImagePath = (
  mainImagePhotoFrame: MainImagePhotoFrame | null
) => {
  const base = '/images/photo/'
  if (mainImagePhotoFrame === MainImagePhotoFrame.Portrait) {
    return base + 'portrait-back.png'
  } else if (mainImagePhotoFrame === MainImagePhotoFrame.Square) {
    return base + 'square-back.png'
  } else if (mainImagePhotoFrame === MainImagePhotoFrame.Landscape) {
    return base + 'landscape-back.png'
  }
  return null
}
