import LuxonUtils from '@date-io/luxon'

import { DateTime } from 'luxon'

export default class CustomLuxonUtils extends LuxonUtils {
  public getCalendarHeaderText(date: DateTime) {
    return this.format(date, 'yyyy年L月')
  }

  // getDatePickerHeaderText(date: DateTime) {
  //   return this.format(date, 'ccc, MMM d')
  // }

  getMonthText(date: DateTime) {
    return this.format(date, 'LLLL')
  }

  getDayText(date: DateTime) {
    return this.format(date, 'd')
  }

  getDateTimePickerHeaderText(date: DateTime) {
    return this.format(date, 'L/d')
  }
}
