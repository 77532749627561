import React, { useState } from 'react'
import clsx from 'clsx'

import { DateTime } from 'luxon'
import _ from 'lodash'
import Typography from '@material-ui/core/Typography'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import { makeStyles } from '@material-ui/core/styles'
import { ListItemText } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'grid',
    width: '100%',
    height: '100%',
    gridTemplateColumns: 'repeat(7, minmax(40px, 1fr))',
    gridTemplateRows: '30px',
    gridAutoRows: 'fit-content'
  },

  dateContainer: {
    borderColor: theme.palette.grey[300],
    borderTopWidth: 1,
    borderLeftWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderStyle: 'solid',
    padding: theme.spacing(1)
  },
  dateContainerMiddle: {
    borderLeftWidth: 1
  },
  dateContainerRight: {
    borderLeftWidth: 1
  },
  dateContainerBottom: {
    // borderBottomWidth: 1
  },
  dayContainer: {},
  dayText: {
    color: theme.palette.grey[700],
    textAlign: 'center',
    verticalAlign: 'middle',
    fontSize: 12
  },
  dateText: {
    fontSize: 14,
    padding: 4,
    fontWeight: 700,
    color: theme.palette.grey[700],
    display: 'inline-block',
    width: 26,
    height: 26,
    textAlign: 'center',
    boxSizing: 'border-box'
  },
  otherMonthDateText: {
    color: theme.palette.grey[400]
  },
  isToday: {
    background: theme.palette.primary.main,
    color: theme.palette.getContrastText(theme.palette.primary.main),
    borderWidth: 1,
    borderRadius: 12
  },
  scheduleList: {
    marginTop: 4,
    paddingTop: 2,
    paddingBottom: 2
  },
  scheduleContainer: {
    paddingTop: 0,
    paddingBottom: 0,
    borderColor: theme.palette.primary.main,
    borderWidth: 1,
    borderStyle: 'solid',
    // backgroundColor: theme.palette.secondary.light,
    '&:hover': {
      backgroundColor: theme.palette.grey[100]
    },
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(1),
    color: theme.palette.primary.main,
    lineHeight: 1
  },
  scheduleTitle: {
    fontSize: 14,
    display: 'inline-block',
    width: '100%'
  },
  scheduleDate: {
    fontSize: 12,
    color: theme.palette.primary.main
  }
}))

export interface Schedule {
  id: any
  from: DateTime
  until: DateTime
  title: string
}

interface CalendarProps {
  startOfMonth: DateTime
  schedules: Schedule[]

  onSelect?: (schedule: Schedule) => void
}

const dayTexts = ['日', '月', '火', '水', '木', '金', '土']

const Calendar: React.FC<CalendarProps> = ({
  startOfMonth,
  schedules,
  onSelect
}) => {
  const classes = useStyles()

  const daysInMonth = startOfMonth.daysInMonth

  const dates: DateTime[] = []
  // last month
  // 先月分は初日days分-1と同じ数
  const lastMonthDayNum = startOfMonth.weekday === 7 ? 0 : startOfMonth.weekday
  _.times(lastMonthDayNum, i => {
    const d = startOfMonth.minus({ days: lastMonthDayNum - i })
    dates.push(d)
  })
  // this month
  _.times(daysInMonth, i => {
    const d = startOfMonth.plus({ days: i })
    dates.push(d)
  })
  // next month
  const eom = startOfMonth.endOf('month')
  const nextMonthDayNum = 6 - eom.weekday
  _.times(nextMonthDayNum, i => {
    const d = eom.plus({ days: i + 1 })
    dates.push(d)
  })

  const dateToSchedule = new Map<number, Schedule[]>()
  schedules.forEach(schedule => {
    const sod = schedule.from.startOf('days').valueOf()
    if (!dateToSchedule.has(sod)) {
      dateToSchedule.set(sod, [schedule])
    } else {
      const v = dateToSchedule.get(sod)
      if (v) {
        v.push(schedule)
      }
    }
  })

  const handleClickSchedule = (schedule: Schedule) => () => {
    if (onSelect) {
      onSelect(schedule)
    }
  }

  const [now] = useState(() => DateTime.local())

  return (
    <div className={classes.root}>
      {_.times(7, i => (
        <div key={i} className={classes.dayContainer}>
          <Typography className={classes.dayText}>{dayTexts[i]}</Typography>
        </div>
      ))}
      {dates.map((d, i) => {
        // const firstRow = i < 7
        const left = d.weekday === 7
        const right = d.weekday === 6
        const bottom = dates.length - i <= 7
        const schedules = dateToSchedule.get(d.valueOf())
        const inMonth = d.month === startOfMonth.month
        const isToday = d.hasSame(now, 'day')

        return (
          <div
            key={d.valueOf()}
            className={clsx(classes.dateContainer, {
              [classes.dateContainerMiddle]: !left && !right,
              [classes.dateContainerRight]: right,
              [classes.dateContainerBottom]: bottom
            })}
          >
            <span
              className={clsx(classes.dateText, {
                [classes.otherMonthDateText]: !inMonth,
                [classes.isToday]: isToday
              })}
            >
              {d.day}
            </span>

            {schedules && (
              <List className={classes.scheduleList}>
                {schedules.map(schedule => {
                  return (
                    <ListItem
                      color="primary"
                      button
                      key={schedule.id}
                      className={classes.scheduleContainer}
                      onClick={handleClickSchedule(schedule)}
                    >
                      <ListItemText
                        primary={schedule.title}
                        secondary={`${schedule.from.toFormat(
                          'H:mm'
                        )}-${schedule.until.toFormat('H:mm')}`}
                        classes={{
                          primary: classes.scheduleTitle,
                          secondary: classes.scheduleDate
                        }}
                        primaryTypographyProps={{
                          noWrap: true
                        }}
                      />
                    </ListItem>
                  )
                })}
              </List>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default Calendar
