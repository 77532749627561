import axios, { AxiosInstance, AxiosResponse } from 'axios'
import axiosRetry, { exponentialDelay } from 'axios-retry'
import { DateTime } from 'luxon'

const baseUrl = process.env.API_BASE_URL

export const createApiClient = () => {
  return axios.create({ baseURL: baseUrl, timeout: 10 * 1000 })
}

export interface PostConcertData {
  name: string
  // eslint-disable-next-line camelcase
  artist_name: string
  // eslint-disable-next-line camelcase
  drawing_artist_name: string | null
  // eslint-disable-next-line camelcase
  opening_at: string
  // eslint-disable-next-line camelcase
  closing_at: string
  // eslint-disable-next-line camelcase
  post_closing_at: string
  // eslint-disable-next-line camelcase
  artist_name_kind: number
  // eslint-disable-next-line camelcase
  artist_name_color: number
  // eslint-disable-next-line camelcase
  date_color: number
  // eslint-disable-next-line camelcase
  main_image_kind: number
  // eslint-disable-next-line camelcase
  main_image_photo_frame: number | null
  // eslint-disable-next-line camelcase
  base64_main_image: string | null
  // eslint-disable-next-line camelcase
  base64_artist_name_logo: string | null
  // eslint-disable-next-line camelcase
  bg_kind: number
  copyright: string | null
  // eslint-disable-next-line camelcase
  base64_base_image: string
  // eslint-disable-next-line camelcase
  base64_restrict_image: string
  // eslint-disable-next-line camelcase
  base64_mask_for_joint_mask_image: string
}

export interface ConcertSummary {
  // eslint-disable-next-line camelcase
  hash_id: string
  name: string
  // eslint-disable-next-line camelcase
  artist_name: string
  // eslint-disable-next-line camelcase
  opening_at: string
  // eslint-disable-next-line camelcase
  closing_at: string
  // eslint-disable-next-line camelcase
  post_closing_at: string
}

export interface ConcertDetail extends ConcertSummary {
  // eslint-disable-next-line camelcase
  user_messages_count: number
  // eslint-disable-next-line camelcase
  artist_messages_count: number
  // eslint-disable-next-line camelcase
  drawing_artist_name: string | null
  // eslint-disable-next-line camelcase
  artist_name_kind: number
  // eslint-disable-next-line camelcase
  artist_name_logo_url: string | null
  // eslint-disable-next-line camelcase
  artist_name_color: number
  // eslint-disable-next-line camelcase
  date_color: number
  // eslint-disable-next-line camelcase
  main_image_kind: number
  // eslint-disable-next-line camelcase
  main_image_photo_frame: number | null
  // eslint-disable-next-line camelcase
  main_image_url: string | null
  // eslint-disable-next-line camelcase
  bg_kind: number
  copyright: string | null
  // eslint-disable-next-line camelcase
  base_image_url: string
  // eslint-disable-next-line camelcase
  restrict_image_url: string
  // eslint-disable-next-line camelcase
  last_pseudo_screen_image_url: string
  // eslint-disable-next-line camelcase
  mask_for_joint_mask_image_url: string
  // eslint-disable-next-line camelcase
  pseudo_screen_base_image_url: string
  // eslint-disable-next-line camelcase
  pseudo_screen_base_image_mini_url: string
  // eslint-disable-next-line camelcase
  cmp_artist_message_image_url: string | null
  // eslint-disable-next-line camelcase
  artist_auth_key: string
}

export interface UserMessage {
  id: number
  // eslint-disable-next-line camelcase
  user_id: number
  x: number
  y: number
  text: string
  // eslint-disable-next-line camelcase
  put_at: string
  // eslint-disable-next-line camelcase
  deleted_at: string
  // eslint-disable-next-line camelcase
  disabled_at: string
  // eslint-disable-next-line camelcase
  rejected_user: boolean
}
export interface UserMessagesPage {
  // eslint-disable-next-line camelcase
  user_messages: UserMessage[]
  total: number
}

export interface NgWord {
  id: number
  word: string
}
export interface NgWordsPage {
  // eslint-disable-next-line camelcase
  ng_words: NgWord[]
  total: number
}

export interface PseudoScreen {
  number: number
  // eslint-disable-next-line camelcase
  image_url: string
  // eslint-disable-next-line camelcase
  rendered_at: string
}

export interface AdminUser {
  id: number
  username: string
  role: string
  // eslint-disable-next-line camelcase
  locked_at: string
}

export interface ErrorResponseData {
  code: string
}
export class ConcertDupTimeError extends Error {}

export class AuthedApiClient {
  client: AxiosInstance

  constructor(token: string) {
    this.client = axios.create({
      baseURL: baseUrl,
      headers: { Authorization: `Bearer ${token}` }
    })
    axiosRetry(this.client, {
      retryDelay: exponentialDelay
    })
  }

  async getValidateToken() {
    return this.client.get('/admin/validate_token')
  }

  async postConcert(data: PostConcertData) {
    try {
      const res = await this.client.post('/admin/concerts', data)
      return res.data
    } catch (e) {
      if (e.response) {
        const res = e.response as AxiosResponse<ErrorResponseData>
        if (res.data.code === 'DupTimeRange') {
          throw new ConcertDupTimeError()
        }
      }
      throw e
    }
  }

  async getConcerts(fromAt: DateTime, untilAt: DateTime) {
    const res = await this.client.get<ConcertSummary[]>('/admin/concerts', {
      params: {
        from_at: fromAt.toISO(),
        until_at: untilAt.toISO()
      }
    })
    return res.data
  }

  async getConcert(hashId: string) {
    const res = await this.client.get<ConcertDetail>(
      `/admin/concerts/${hashId}`
    )
    return res.data
  }

  async updateConcert(hashId: string, data: PostConcertData) {
    try {
      const res = await this.client.put(`/admin/concerts/${hashId}`, data)
      return res.data
    } catch (e) {
      if (e.response) {
        const res = e.response as AxiosResponse<ErrorResponseData>
        if (res.data.code === 'DupTimeRange') {
          throw new ConcertDupTimeError()
        }
      }
      throw e
    }
  }

  async deleteConcert(hashId: string) {
    const res = await this.client.delete(`/admin/concerts/${hashId}`)
    return res.data
  }

  async getUserMessages(hashId: string, params: any) {
    const res = await this.client.get<UserMessagesPage>(
      `/admin/concerts/${hashId}/messages`,
      { params }
    )
    return res.data
  }

  async disableUserMessage(id: number) {
    const res = await this.client.put(`/admin/concerts/messages/${id}/disable`)
    return res.data
  }

  async enableUserMessage(id: number) {
    const res = await this.client.put(`/admin/concerts/messages/${id}/enable`)
    return res.data
  }

  async rejectUser(id: number) {
    const res = await this.client.put(`/admin/concerts/users/${id}/reject`)
    return res.data
  }

  async cancelRejectUser(id: number) {
    const res = await this.client.put(
      `/admin/concerts/users/${id}/cancel_reject`
    )
    return res.data
  }

  async recreatePseudoScreen(id: number) {
    const res = await this.client.post(
      `/admin/concerts/messages/${id}/recreate_pseudo_screen`
    )
    return res.data
  }

  async getNgWords(params: any) {
    const res = await this.client.get<NgWordsPage>(`/admin/ng_words`, {
      params
    })
    return res.data
  }

  async importNgWords(words: string[]) {
    const res = await this.client.post('/admin/ng_words/import', { words })
    return res.data
  }

  async getConcertNgWords(hashId: string, params: any) {
    const res = await this.client.get<NgWordsPage>(
      `/admin/concerts/${hashId}/ng_words`,
      { params }
    )
    return res.data
  }

  async postConcertNgWord(hashId: string, word: string) {
    const res = await this.client.post<NgWord>(
      `/admin/concerts/${hashId}/ng_words`,
      {
        word
      }
    )
    return res.data
  }

  async deleteConcertNgWord(hashId: string, id: number) {
    const res = await this.client.delete(
      `/admin/concerts/${hashId}/ng_words/${id}`
    )
    return res.data
  }

  async getSnapshotPseudoScreens(hashId: string) {
    const res = await this.client.get<PseudoScreen[]>(
      `/admin/concerts/${hashId}/snap_shot_pseudo_screens`
    )
    return res.data
  }

  async getConcertImageZip(hashId: string): Promise<Blob> {
    const res = await this.client.get(
      `/admin/concerts/${hashId}/messages/image_zip`,
      { responseType: 'blob' }
    )
    return res.data
  }

  async changePassword(password: string) {
    const res = await this.client.put(`/admin/password`, {
      password
    })
    return res
  }

  async getAdminUsers() {
    const res = await this.client.get<AdminUser[]>('/admin/admin_users')
    return res.data
  }

  async postAdminUser(username: string, password: string, role: string) {
    const res = await this.client.post<AdminUser>(`/admin/admin_users`, {
      username,
      password,
      role
    })
    return res.data
  }

  async deleteAdminUser(id: number) {
    const res = await this.client.delete(`/admin/admin_users/${id}`)
    return res.data
  }

  async unlockAdminUser(id: number) {
    const res = await this.client.put(`/admin/admin_users/${id}/unlock`)
    return res.data
  }
}
