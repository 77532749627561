const timesInterval = (
  num: number,
  interval: number,
  fn: (i: number) => void
) => {
  const proc = (i: number) => {
    fn(i)
    if (i < num) {
      setTimeout(() => proc(i + 1), interval)
    }
  }

  const start = () => {
    proc(0)
  }
  return start
}

export default timesInterval
