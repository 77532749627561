import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import ImageIcon from '@material-ui/icons/Image'

interface PlaceHolderImageProps {
  width: number
  height: number
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.grey[300],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}))

const PlaceholderImage: React.FC<PlaceHolderImageProps> = ({
  width,
  height
}) => {
  const classes = useStyles()
  return (
    <div style={{ width, height }} className={classes.root}>
      <ImageIcon fontSize="large" />
    </div>
  )
}

export default PlaceholderImage
