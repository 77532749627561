import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import BaseField from './BaseField'
import Link from '@material-ui/core/Link'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  value: {
    fontSize: '14pt',
    display: 'flex',
    alignItems: 'center'
  }
}))
interface UrlFieldProps {
  name: string
  value: any
}
const UrlField: React.FC<UrlFieldProps> = ({ name, value, children }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <BaseField name={name}>
        <Link
          href={value}
          className={classes.value}
          target="_blank"
          rel="noopener"
        >
          {children}
        </Link>
      </BaseField>
    </div>
  )
}

export default UrlField
