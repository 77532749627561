import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import { RootState } from '../reducers'
import { AuthState } from '../reducers/auth'

import { AuthedApiClient } from './api_client'

export default function useAuthedApiClient() {
  const auth = useSelector<RootState, AuthState>(state => state.auth)

  const [client, setClient] = useState<AuthedApiClient | null>(null)

  useEffect(() => {
    if (auth.token) {
      const c = new AuthedApiClient(auth.token)
      setClient(c)
    }
  }, [auth.token])

  return client
}
