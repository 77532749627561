import React, { useState, useEffect } from 'react'
import clsx from 'clsx'

import { DateTime } from 'luxon'

import Paper from '@material-ui/core/Paper'
import {
  DataTypeProvider,
  DataTypeProviderProps
} from '@devexpress/dx-react-grid'
import { Table } from '@devexpress/dx-react-grid-material-ui'

import { Link as RouterLink, useRouteMatch } from 'react-router-dom'

import { UserMessage, ConcertDetail } from '../../../lib/api_client'
import useAuthedApiClient from '../../../lib/useAuthedApiClient'

import CircularProgress from '@material-ui/core/CircularProgress'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import TextField from '@material-ui/core/TextField'
import Chip from '@material-ui/core/Chip'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

import { makeStyles } from '@material-ui/core/styles'

import SearchIcon from '@material-ui/icons/Search'
import LinkIcon from '@material-ui/icons/Launch'

import UrlFormatter from '../../../components/formatter/UrlFormatter'
import ImageFormatter from '../../../components/formatter/ImageFormatter'
import TimeFormatter from '../../../components/formatter/TimeFormatter'
import NoWrapTextFormatter from '../../../components/formatter/NoWrapTextFormatter'
import DefaultLayout from '../../../layout/DefaultLayout'

import PaginateGrid, {
  PageQueryParams,
  Filter
} from '../../../components/PaginateGrid'
import { KeyboardDateTimePicker } from '@material-ui/pickers'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'

const useStyles = makeStyles(theme => ({
  breadcrumbs: {
    paddingBottom: theme.spacing(1)
  },
  filterContainer: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: theme.spacing(1)
  },
  field: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  textFilter: {
    width: 150
  },
  idFilter: {
    width: 100
  },
  userFilter: {
    width: 100
  },
  putAtFilter: {
    width: 200
  },
  statusFilter: {
    width: 100
  },
  userId: {
    marginBottom: theme.spacing(1)
  },
  status: {
    display: 'flex',
    flexDirection: 'column'
  }
}))

const UserIdFormatter: React.FC<DataTypeProvider.ValueFormatterProps> = ({
  row,
  value
}) => {
  const classes = useStyles({})
  return (
    <div>
      <div className={classes.userId}>{value}</div>
      {row.rejected_user && <Chip label="BAN済" />}
    </div>
  )
}

const UserIdTypeProvider: React.FC<DataTypeProviderProps> = props => (
  <DataTypeProvider formatterComponent={UserIdFormatter} {...props} />
)

const DISABLE_ACTION = 'disable'
const ENABLE_ACTION = 'enable'
const REJECT_USER_ACTION = 'reject_user'
const CANCEL_REJECT_USER_ACTION = 'cancel_reject_user'
const RECREATE_PSEUDO_SCREEN_ACTION = 'recreate_pseudo_screen'
const ActionFormatter: React.FC<DataTypeProvider.ValueFormatterProps> = ({
  value,
  row
}) => {
  const { handler } = value
  const handleDisable = () => {
    handler(DISABLE_ACTION, row)
  }
  const handleEnable = () => {
    handler(ENABLE_ACTION, row)
  }
  const handleRejectUser = () => {
    handler(REJECT_USER_ACTION, row)
  }
  const handleCancelRejectUser = () => {
    handler(CANCEL_REJECT_USER_ACTION, row)
  }
  const handleRecreatePseudoScreen = () => {
    handler(RECREATE_PSEUDO_SCREEN_ACTION, row)
  }

  const style = { display: 'block' }
  return (
    <>
      {row.deleted_at === null && (
        <>
          {row.disabled_at === null && (
            <Button color="primary" onClick={handleDisable} style={style}>
              非表示
            </Button>
          )}
          {row.disabled_at !== null && (
            <Button color="primary" onClick={handleEnable} style={style}>
              再表示
            </Button>
          )}
        </>
      )}

      {!row.rejected_user && (
        <Button color="primary" onClick={handleRejectUser} style={style}>
          ユーザーBAN
        </Button>
      )}
      {row.rejected_user && (
        <Button color="primary" onClick={handleCancelRejectUser} style={style}>
          ユーザーを戻す
        </Button>
      )}
      <Button
        color="primary"
        onClick={handleRecreatePseudoScreen}
        style={style}
      >
        擬似スクリーン再生成
      </Button>
    </>
  )
}

const StatusFormatter: React.FC<DataTypeProvider.ValueFormatterProps> = ({
  row
}) => {
  const deleted = row.deleted_at !== null
  const disabled = row.disabled_at !== null

  const classes = useStyles({})
  return (
    <div className={classes.status}>
      {deleted && <Chip label="削除済" />}
      {disabled && <Chip label="非表示済" />}
    </div>
  )
}
const StatusTypeProvider: React.FC<DataTypeProviderProps> = props => (
  <DataTypeProvider formatterComponent={StatusFormatter} {...props} />
)

const ImageTypeProvider: React.FC<DataTypeProviderProps> = props => (
  <DataTypeProvider formatterComponent={ImageFormatter} {...props} />
)

const ActionTypeProvider: React.FC<DataTypeProviderProps> = props => (
  <DataTypeProvider formatterComponent={ActionFormatter} {...props} />
)

const UrlTypeProvider: React.FC<DataTypeProviderProps> = props => (
  <DataTypeProvider formatterComponent={UrlFormatter} {...props} />
)

const TimeTypeProvider: React.FC<DataTypeProviderProps> = props => (
  <DataTypeProvider formatterComponent={TimeFormatter} {...props} />
)

const NoWrapTextTypeProvider: React.FC<DataTypeProviderProps> = props => (
  <DataTypeProvider formatterComponent={NoWrapTextFormatter} {...props} />
)

const useStylesPseudoScreensFormatter = makeStyles(theme => ({
  link: {
    display: 'flex',
    alignItems: 'center'
  },
  linkIcon: {
    marginRight: theme.spacing(1)
  }
}))
const PseudoScreensFormatter: React.FC<
  DataTypeProvider.ValueFormatterProps
> = ({ value }) => {
  const classes = useStylesPseudoScreensFormatter()
  return (
    <>
      {value.map((ps: any) => (
        <p key={ps.id}>
          {ps.image_url && (
            <Link
              href={ps.image_url}
              className={classes.link}
              target="_blank"
              rel="noopener"
            >
              <LinkIcon className={classes.linkIcon} />
              ID: {ps.id}
            </Link>
          )}
          {!ps.image_url && <div>ID: {ps.id}(未))</div>}
        </p>
      ))}
    </>
  )
}
const PseudoScreensTypeProvider: React.FC<DataTypeProviderProps> = props => (
  <DataTypeProvider formatterComponent={PseudoScreensFormatter} {...props} />
)

const interpretStatusFilter = (v: string): ColumnToFilter => {
  if (v === 'deleted') {
    return {
      deleted_at: {
        op: 'not_null',
        values: []
      }
    }
  } else if (v === 'disabled') {
    return {
      disabled_at: {
        op: 'not_null',
        values: []
      }
    }
  } else if (v === 'normal') {
    return {
      deleted_at: {
        op: 'null',
        values: []
      },
      disabled_at: {
        op: 'null',
        values: []
      }
    }
  } else {
    return {}
  }
}

interface Row extends UserMessage {
  action: {
    handler: (name: string, row: Row) => void
  }
}

type ColumnToFilter = {
  [column: string]: Omit<Filter, 'column'>
}

export interface UserMessageListProps {
  kind: 'normal' | 'artist'
}
const UserMessageList: React.FC<UserMessageListProps> = ({ kind }) => {
  const match = useRouteMatch()
  const concertId = match.params.id

  const classes = useStyles()

  const [columns] = useState([
    { name: 'id', title: 'ID' },
    { name: 'user_id', title: 'ユーザーID' },
    { name: 'x', title: 'X' },
    { name: 'y', title: 'Y' },
    { name: 'painted_image_url', title: 'ペイント画像' },
    { name: 'text', title: 'テキスト' },
    { name: 'created_at', title: '投稿日時' },
    { name: 'put_at', title: 'アップ日時' },
    { name: 'rendered_image_url', title: 'タレ画像' },
    { name: 'pseudo_screens', title: '擬似スクリーン画像' },
    { name: 'status', title: 'ステータス' },
    { name: 'action', title: 'アクション' }
  ])
  const [userIdColumns] = useState(['user_id'])
  const [statusColumns] = useState(['status'])
  const [urlColumns] = useState(['rendered_image_url'])
  const [imageColumns] = useState(['painted_image_url'])
  const [actionColumns] = useState(['action'])
  const [timeColumns] = useState(['created_at', 'put_at', 'disabled_at'])
  const [pseudoScreensColumns] = useState(['pseudo_screens'])
  const [noWrapTextColumns] = useState(['text'])

  const [rows, setRows] = useState<Row[]>([])
  const [tableColumnExtensions] = useState<Table.ColumnExtension[]>([
    { columnName: 'id', width: 90 },
    { columnName: 'status', width: 110 },
    { columnName: 'user_id', width: 110 },
    { columnName: 'x', width: 90 },
    { columnName: 'y', width: 90 },
    { columnName: 'painted_image_url', width: 150 }
  ])

  const [sortingStateColumnExtensions] = useState([
    { columnName: 'status', sortingEnabled: false },
    { columnName: 'action', sortingEnabled: false }
  ])

  const [totalCount, setTotalCount] = useState(0)
  const [loading, setLoading] = useState(false)
  // const [lastQuery, setLastQuery] = useState<QueryParams>()
  const [pageQuery, setPageQuery] = useState<PageQueryParams | null>(null)

  // filter
  const [colToFilter, setColToFilter] = useState<ColumnToFilter>({})
  const [filter, setFilter] = useState<Filter[]>([])
  const handleChangeFilter = (column: string, op: 'equal' | 'contains') => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const val = e.target.value
    if (val) {
      setColToFilter({
        ...colToFilter,
        [column]: { op, values: [val] }
      })
    } else {
      delete colToFilter[column]
      setColToFilter({
        ...colToFilter
      })
    }
  }

  const handleChangeFilterBetween = (column: string, isFrom: boolean) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const i = isFrom ? 0 : 1
    if (colToFilter[column] && colToFilter[column].op === 'between') {
    } else {
      colToFilter[column] = {
        op: 'between',
        values: [null, null]
      }
    }

    colToFilter[column].values[i] = e.target.value ? e.target.value : null

    setColToFilter({ ...colToFilter })
  }

  const handleChangeFilterBetweenPicker = (column: string, isFrom: boolean) => (
    date: DateTime | null
  ) => {
    const i = isFrom ? 0 : 1
    if (colToFilter[column] && colToFilter[column].op === 'between') {
    } else {
      colToFilter[column] = {
        op: 'between',
        values: [null, null]
      }
    }

    colToFilter[column].values[i] = date

    setColToFilter({ ...colToFilter })
  }

  const [statusFilter, setStatusFilter] = useState('')
  const handleChangeStatusFilter = (
    e: React.ChangeEvent<{ value: unknown }>
  ) => {
    const v = e.target.value as string
    setStatusFilter(v)
  }

  const handleApplyFilter = () => {
    // statusをマージ
    const s = interpretStatusFilter(statusFilter)
    const cf: ColumnToFilter = { ...colToFilter, ...s }

    const filter: Filter[] = []
    for (const col in cf) {
      const f = cf[col]
      filter.push({
        column: col,
        op: f.op,
        values: f.values
      })
    }

    setFilter(filter)
  }

  const client = useAuthedApiClient()

  const [concert, setConcert] = useState<ConcertDetail | null>(null)

  useEffect(() => {
    client && client.getConcert(concertId).then(c => setConcert(c))
  }, [client, concertId])

  const handleAction = async (name: string, row: Row) => {
    if (client) {
      if (name === DISABLE_ACTION) {
        const id = row.id
        await client.disableUserMessage(id)
        loadData()
      } else if (name === ENABLE_ACTION) {
        const id = row.id
        await client.enableUserMessage(id)
        loadData()
      } else if (name === REJECT_USER_ACTION) {
        await client.rejectUser(row.user_id)
        loadData()
      } else if (name === CANCEL_REJECT_USER_ACTION) {
        await client.cancelRejectUser(row.user_id)
        loadData()
      } else if (name === RECREATE_PSEUDO_SCREEN_ACTION) {
        const id = row.id
        await client.recreatePseudoScreen(id)
        loadData()
      }
    }
  }
  const loadData = () => {
    console.log('loadData', 'pageQuery', pageQuery)

    const qs = { ...pageQuery, user_kind: kind }
    if (client !== null && !loading) {
      setLoading(true)

      client
        .getUserMessages(concertId, qs)
        .then(data => {
          setRows(
            data.user_messages.map(
              (um: UserMessage): Row => {
                return { ...um, action: { handler: handleAction } }
              }
            )
          )
          setTotalCount(data.total)
          setLoading(false)
        })
        .catch(() => setLoading(false))
      // setLastQuery(qs)
    }
  }

  useEffect(() => loadData(), [client, pageQuery])

  const title =
    kind === 'normal' ? 'ユーザーメッセージ' : 'アーティストメッセージ'
  return (
    <DefaultLayout title={title}>
      <Breadcrumbs className={classes.breadcrumbs}>
        <Link component={RouterLink} to="/">
          コンサート
        </Link>
        <Link component={RouterLink} to={`/concerts/${concertId}/show`}>
          {concert && concert.name}
        </Link>
        <Typography color="textPrimary">{title}</Typography>
      </Breadcrumbs>

      <div className={classes.filterContainer}>
        <TextField
          label="ID"
          value={
            colToFilter['user_messages.id']
              ? colToFilter['user_messages.id'].values[0]
              : null
          }
          onChange={handleChangeFilterBetween('user_messages.id', true)}
          className={clsx(classes.field, classes.idFilter)}
        />
        〜
        <TextField
          label=""
          value={
            colToFilter['user_messages.id']
              ? colToFilter['user_messages.id'].values[1]
              : null
          }
          onChange={handleChangeFilterBetween('user_messages.id', false)}
          className={clsx(classes.field, classes.idFilter)}
        />
        <TextField
          label="ユーザーID"
          value={colToFilter.user_id ? colToFilter.user_id.values[0] : null}
          onChange={handleChangeFilter('user_id', 'equal')}
          className={clsx(classes.field, classes.userFilter)}
        />
        <TextField
          label="テキスト"
          value={colToFilter.text ? colToFilter.text.values[0] : null}
          onChange={handleChangeFilter('text', 'contains')}
          className={clsx(classes.field, classes.textFilter)}
        />
        <KeyboardDateTimePicker
          variant="inline"
          label="投稿日時"
          ampm={false}
          value={
            colToFilter['user_messages.created_at']
              ? colToFilter['user_messages.created_at'].values[0]
              : null
          }
          onChange={handleChangeFilterBetweenPicker(
            'user_messages.created_at',
            true
          )}
          format="yyyy/LL/dd HH:mm"
          className={clsx(classes.field, classes.putAtFilter)}
        />
        〜
        <KeyboardDateTimePicker
          variant="inline"
          ampm={false}
          value={
            colToFilter['user_messages.created_at']
              ? colToFilter['user_messages.created_at'].values[1]
              : null
          }
          onChange={handleChangeFilterBetweenPicker(
            'user_messages.created_at',
            false
          )}
          format="yyyy/LL/dd HH:mm"
          className={clsx(classes.field, classes.putAtFilter)}
        />
        <FormControl className={clsx(classes.field, classes.statusFilter)}>
          <InputLabel id="status-filter-label">ステータス</InputLabel>
          <Select
            labelId="status-filter-label"
            id="status-filter"
            value={statusFilter}
            onChange={handleChangeStatusFilter}
          >
            <MenuItem value="">&nbsp;</MenuItem>
            <MenuItem value="normal">通常</MenuItem>
            <MenuItem value="deleted">削除済み</MenuItem>
            <MenuItem value="disabled">非表示</MenuItem>
          </Select>
        </FormControl>
        <Button variant="contained" onClick={handleApplyFilter}>
          <SearchIcon />
        </Button>
      </div>

      <Paper>
        <PaginateGrid
          rows={rows}
          columns={columns}
          totalCount={totalCount}
          filter={filter}
          onChangePage={setPageQuery}
          sortingStateColumnExtensions={sortingStateColumnExtensions}
        >
          <UserIdTypeProvider for={userIdColumns} />
          <StatusTypeProvider for={statusColumns} />
          <UrlTypeProvider for={urlColumns} />
          <ImageTypeProvider for={imageColumns} />
          <ActionTypeProvider for={actionColumns} />
          <TimeTypeProvider for={timeColumns} />
          <NoWrapTextTypeProvider for={noWrapTextColumns} />
          <PseudoScreensTypeProvider for={pseudoScreensColumns} />

          <Table columnExtensions={tableColumnExtensions} />
        </PaginateGrid>
        {loading && <CircularProgress />}
      </Paper>
    </DefaultLayout>
  )
}

export default UserMessageList
