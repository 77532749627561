import React from 'react'

export interface GuideLineProps {
  className?: string
  width: number
  height: number
  show: boolean
}

const GuideLine: React.FC<GuideLineProps> = ({
  width,
  height,
  show,
  className
}) => {
  if (!show) {
    return <></>
  }
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      // style={{ backgroundColor: 'white' }}
    >
      <line
        x1={0}
        y1={705}
        x2={width}
        y2={705}
        stroke="white"
        strokeWidth={2}
      />

      <text x={400} y={705} fill="white" fontSize={48}>
        705
      </text>

      <line
        x1={0}
        y1={715}
        x2={width}
        y2={715}
        stroke="white"
        strokeWidth={2}
      />

      <text x={300} y={715} fill="white" fontSize={48}>
        715
      </text>

      <line
        x1={0}
        y1={200}
        x2={width}
        y2={200}
        stroke="white"
        strokeWidth={2}
      />

      <text x={300} y={200} fill="white" fontSize={48}>
        200
      </text>

      <line
        x1={0}
        y1={height / 2}
        x2={width}
        y2={height / 2}
        stroke="white"
        strokeWidth={2}
      />

      <text x={300} y={height / 2} fill="white" fontSize={48}>
        half
      </text>

      <line
        x1={width / 2}
        y1={0}
        x2={width / 2}
        y2={height}
        stroke="white"
        strokeWidth={2}
      />

      <text x={width / 2} y={50} fill="white" fontSize={48}>
        half
      </text>

      <line
        x1={(width - 1600) / 2}
        y1={0}
        x2={(width - 1600) / 2}
        y2={height}
        stroke="white"
        strokeWidth={2}
      />

      <text x={(width - 1600) / 2} y={50} fill="white" fontSize={48}>
        half - 800
      </text>

      <line
        x1={(width - 1600) / 2 + 1600}
        y1={0}
        x2={(width - 1600) / 2 + 1600}
        y2={height}
        stroke="white"
        strokeWidth={2}
      />

      <text x={(width - 1600) / 2 + 1600} y={50} fill="white" fontSize={48}>
        half + 800
      </text>
    </svg>
  )
}

export default GuideLine
