import React, { useEffect, useState } from 'react'

import DefaultLayout from '../../layout/DefaultLayout'
import useAuthedApiClient from '../../lib/useAuthedApiClient'
import { AdminUser } from '../../lib/api_client'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'

import {
  Grid,
  Table,
  TableHeaderRow
} from '@devexpress/dx-react-grid-material-ui'

import { makeStyles, createStyles } from '@material-ui/core/styles'

import { DataTypeProvider } from '@devexpress/dx-react-grid'

import { DeleteAdminUserConfirm } from './DeleteAdminUserConfirm'
import { NewAdminUserDialog } from './NewAdminUserDialog'
import { useOpen } from '@maruware/material-ui-hooks'

import { DateTime } from 'luxon'

const useStyles = makeStyles(theme =>
  createStyles({
    root: {},
    head: {
      margin: theme.spacing(1)
    },
    content: {
      margin: theme.spacing(1)
    },
    lockedAtText: {
      marginRight: theme.spacing(1)
    }
  })
)

const roleLabel = (role: string) => {
  switch (role) {
    case 'normal':
      return '制限アカウント'
    case 'super':
      return '管理アカウント'
    default:
      return role
  }
}

const DELETE_ACTION = 'delete'
const UNLOCK_ACTION = 'unlock'
const ActionFormatter: React.FC<DataTypeProvider.ValueFormatterProps> = ({
  value,
  row
}) => {
  const handler = value
  const handleClick = () => {
    handler(DELETE_ACTION, row)
  }
  return (
    <Button color="secondary" variant="outlined" onClick={handleClick}>
      削除
    </Button>
  )
}

const LockedAtFormatter: React.FC<DataTypeProvider.ValueFormatterProps> = ({
  value,
  row
}) => {
  const handler = row.action
  const classes = useStyles({})
  const handleClick = () => {
    handler(UNLOCK_ACTION, row)
  }
  return (
    <span>
      {value && (
        <>
          <span className={classes.lockedAtText}>
            {DateTime.fromISO(value).toFormat('y/L/d HH:mm')}
          </span>
          <Button variant="outlined" onClick={handleClick}>
            解除
          </Button>
        </>
      )}
    </span>
  )
}

interface Row extends AdminUser {
  action: (code: string, target: AdminUser) => void
}

interface AdminUserListProps {}
const AdminUserList: React.FC<AdminUserListProps> = () => {
  const client = useAuthedApiClient()
  const [adminUsers, setAdminUsers] = useState<AdminUser[]>([])

  const load = () => {
    if (client) {
      client.getAdminUsers().then(setAdminUsers)
    }
  }

  useEffect(() => {
    load()
  }, [client])

  const handleUnlock = async (id: number) => {
    if (client) {
      await client.unlockAdminUser(id)
      load()
    }
  }

  const [deletingAdminUser, setDeletingAdminUser] = useState<AdminUser>()
  const handleAction = async (code: string, target: AdminUser) => {
    if (client) {
      if (code === DELETE_ACTION) {
        setDeletingAdminUser(target)
      } else if (code === UNLOCK_ACTION) {
        handleUnlock(target.id)
      }
    }
  }

  const handleDelete = async () => {
    if (!deletingAdminUser) {
      throw new Error('Unexpected')
    }
    if (!client) {
      throw new Error('no client')
    }
    await client.deleteAdminUser(deletingAdminUser.id)
    load()
    handleCloseDeleteConfirm()
  }

  const handleCloseDeleteConfirm = () => {
    setDeletingAdminUser(undefined)
  }

  const [rows, setRows] = useState<Row[]>([])

  useEffect(() => {
    if (adminUsers.length > 0) {
      setRows(
        adminUsers.map(adminUser => ({
          ...adminUser,
          role: roleLabel(adminUser.role),
          action: handleAction
        }))
      )
    }
  }, [adminUsers])

  const [openNew, handleOpenNew, handleCloseNew] = useOpen()
  const handleRegist = async (
    username: string,
    password: string,
    role: string
  ) => {
    if (client) {
      await client.postAdminUser(username, password, role)
      load()
      handleCloseNew()
    }
  }

  const [columns] = useState([
    { name: 'username', title: 'ユーザー名' },
    { name: 'role', title: 'ロール' },
    { name: 'locked_at', title: 'ロック日時' },
    { name: 'action', title: 'アクション' }
  ])

  const [actionColumns] = useState(['action'])
  const [lockedAtColumns] = useState(['locked_at'])

  const classes = useStyles({})

  return (
    <DefaultLayout title="アカウント">
      <div className={classes.root}>
        <div className={classes.head}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            component="label"
            onClick={handleOpenNew}
          >
            アカウントの発行
          </Button>
        </div>
        <div className={classes.content}>
          <Card>
            <CardContent>
              <Grid rows={rows} columns={columns}>
                <Table />
                <TableHeaderRow />

                <DataTypeProvider
                  for={actionColumns}
                  formatterComponent={ActionFormatter}
                />

                <DataTypeProvider
                  for={lockedAtColumns}
                  formatterComponent={LockedAtFormatter}
                />
              </Grid>
            </CardContent>
          </Card>
        </div>
      </div>

      <DeleteAdminUserConfirm
        adminUser={deletingAdminUser}
        onExecute={handleDelete}
        onClose={handleCloseDeleteConfirm}
      />

      <NewAdminUserDialog
        open={openNew}
        onClose={handleCloseNew}
        onRegist={handleRegist}
      />
    </DefaultLayout>
  )
}

export default AdminUserList
