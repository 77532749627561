import React, { useEffect, useState, useMemo } from 'react'

import { useHistory, useRouteMatch, Link as RouterLink } from 'react-router-dom'

import { ConcertDetail } from '../../lib/api_client'
import useAuthedApiClient from '../../lib/useAuthedApiClient'

import LinearProgress from '@material-ui/core/LinearProgress'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import CardActionArea from '@material-ui/core/CardActionArea'
import CardMedia from '@material-ui/core/CardMedia'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogActions from '@material-ui/core/DialogActions'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Typography from '@material-ui/core/Typography'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/DeleteForever'
import LinkIcon from '@material-ui/icons/Launch'
import MoreIcon from '@material-ui/icons/MoreVert'
import UserMessageIcon from '@material-ui/icons/EmojiPeople'
import ArtistIcon from '../../components/MusicianIcon'
import SnapShotIcon from '@material-ui/icons/PhotoLibrary'
import NGWordIcon from '@material-ui/icons/Security'

import { makeStyles } from '@material-ui/core/styles'

import TextField from '../../components/field/TextField'
import DateField from '../../components/field/DateField'
import QRCodeImage from '../../components/QRCodeImage'
import clsx from 'clsx'

import DefaultLayout from '../../layout/DefaultLayout'
import UrlField from '../../components/field/UrlField'

import { CountSummary } from '../../components/CountSummary'
import { SquareButton } from '../../components/SquareButton'

import download from 'downloadjs'
import { useRole } from '../../lib/use-role'

const useStyles = makeStyles(theme => ({
  root: {},
  breadcrumbs: {
    paddingBottom: theme.spacing(1)
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  left: {
    marginRight: theme.spacing(3)
  },
  right: {
    flexGrow: 1
  },
  card: {
    marginBottom: theme.spacing(1)
  },
  main: {
    // display: 'flex'
    width: 450
  },
  mainDescs: {
    display: 'flex',
    flexDirection: 'column'
  },
  mainContent: {
    flex: '1 0 auto'
  },
  media: {
    height: 0,
    paddingTop: '75%'
  },
  siteLinks: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  siteLinkContainer: {
    display: 'flex',
    // alignItems: 'center',
    // marginLeft: theme.spacing(2),
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(2),
    maxWidth: 440
  },
  siteLinkDesc: {
    fontSize: 18,
    marginBottom: theme.spacing(1)
  },
  siteLink: {
    wordBreak: 'break-all'
  },
  routerLinkContainer: {
    marginBottom: theme.spacing(2)
  },
  routerLink: {
    fontSize: 16
  },
  dialogImage: {
    width: '100%',
    objectFit: 'contain'
  },
  previewLinks: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  previewLink: {
    marginRight: theme.spacing(1),
    display: 'inline-flex'
  },
  qrImage: {
    marginRight: theme.spacing(2)
  },
  statsContainer: {
    display: 'flex'
  },
  statsItem: {
    marginRight: theme.spacing(1)
  },
  squareButton: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}))

const SITE_BASE_URL = process.env.SITE_BASE_URL

interface PreviewLinkProps {
  href: string
  className: string
  text: string
}
const PreviewLink: React.FC<PreviewLinkProps> = ({ href, className, text }) => {
  return (
    <Link className={className} href={href} target="_blank" rel="noopener">
      <LinkIcon />
      {text}
    </Link>
  )
}

interface IdMatch {
  id: string
}
interface ConcertShowProps {}
const ConcertShow: React.FC<ConcertShowProps> = () => {
  const classes = useStyles()
  const history = useHistory()
  const match = useRouteMatch()
  const id = match.params.id

  const { isSuper } = useRole()

  const [concert, setConcert] = useState<ConcertDetail | null>(null)

  const client = useAuthedApiClient()

  const [dispImage, setDispImage] = useState(false)
  const [confirmOpen, setConfirmOpen] = useState(false)

  useEffect(() => {
    client && client.getConcert(id).then(c => setConcert(c))
  }, [client, id])

  const goEdit = () => {
    history.push(`/concerts/${id}/edit`)
  }

  const goPage = (page: string) => () => {
    history.push(page)
  }

  const userPage = `${SITE_BASE_URL}/?cid=${id}`
  const artistPage = useMemo(
    () =>
      concert
        ? `${SITE_BASE_URL}/?cid=${id}&key=${concert.artist_auth_key}#/tablet`
        : '',
    [concert]
  )

  const handleClickMedia = () => {
    setDispImage(true)
  }
  const handleCloseDispImage = () => {
    setDispImage(false)
  }

  const handleOpenConfirm = () => {
    setConfirmOpen(true)
  }
  const handleCloseConfirm = () => {
    setConfirmOpen(false)
  }
  const handleClickDelete = () => {
    client && client.deleteConcert(id).then(() => history.push(`/`))
  }

  const [
    moreActionAnchorEl,
    setMoreActionAnchorEl
  ] = useState<HTMLElement | null>(null)
  const handleOpenMoreAction = (e: React.MouseEvent<HTMLButtonElement>) => {
    setMoreActionAnchorEl(e.currentTarget)
  }
  const handleCloseMoreAction = () => {
    setMoreActionAnchorEl(null)
  }

  const handleDownloadZip = async () => {
    if (client) {
      const r = await client.getConcertImageZip(id)
      download(r, `${id}.zip`, 'application/zip')
      setMoreActionAnchorEl(null)
    }
  }

  return (
    <DefaultLayout title={concert ? concert.name : ''}>
      {!concert && <LinearProgress />}
      {concert && (
        <div className={classes.root}>
          <Breadcrumbs className={classes.breadcrumbs}>
            <Link component={RouterLink} to="/">
              コンサート
            </Link>
            <Typography color="textPrimary">{concert.name}</Typography>
          </Breadcrumbs>

          <div className={classes.container}>
            <div className={classes.left}>
              <Card className={clsx(classes.card, classes.main)}>
                <CardActionArea onClick={handleClickMedia}>
                  <CardMedia
                    className={classes.media}
                    image={concert.pseudo_screen_base_image_mini_url}
                  />
                </CardActionArea>

                <CardContent className={classes.mainContent}>
                  <TextField name="ID" value={id} />
                  <TextField name="コンサート名" value={concert.name} />
                  <TextField
                    name="アーティスト名"
                    value={concert.artist_name}
                  />
                  <DateField name="開始時刻" value={concert.opening_at} />
                  <DateField name="終了時刻" value={concert.closing_at} />
                  <DateField
                    name="投稿終了時刻"
                    value={concert.post_closing_at}
                  />
                  {concert.last_pseudo_screen_image_url && (
                    <UrlField
                      name="最終擬似スクリーン"
                      value={concert.last_pseudo_screen_image_url}
                    >
                      <LinkIcon />
                      画像を開く
                    </UrlField>
                  )}
                  {!concert.last_pseudo_screen_image_url && (
                    <TextField name="最終擬似スクリーン" value="なし" />
                  )}
                </CardContent>
                {isSuper && (
                  <CardActions>
                    <Button size="large" onClick={goEdit}>
                      <EditIcon />
                      編集する
                    </Button>
                    <Button size="large" onClick={handleOpenConfirm}>
                      <DeleteIcon />
                      削除する
                    </Button>

                    <Button size="large" onClick={handleOpenMoreAction}>
                      <MoreIcon />
                    </Button>

                    <Menu
                      anchorEl={moreActionAnchorEl}
                      keepMounted
                      open={Boolean(moreActionAnchorEl)}
                      onClose={handleCloseMoreAction}
                    >
                      <MenuItem onClick={handleDownloadZip}>
                        zipダウンロード
                      </MenuItem>
                    </Menu>
                  </CardActions>
                )}
              </Card>
            </div>

            <div className={classes.right}>
              <div className={classes.statsContainer}>
                <Card className={clsx(classes.card, classes.statsItem)}>
                  <CardContent>
                    <CountSummary
                      label="ユーザー投稿数"
                      count={concert.user_messages_count}
                    />
                  </CardContent>
                </Card>
                <Card className={clsx(classes.card, classes.statsItem)}>
                  <CardContent>
                    <CountSummary
                      label="アーティスト投稿数"
                      count={concert.artist_messages_count}
                    />
                  </CardContent>
                </Card>
              </div>

              <Card className={classes.card}>
                <CardHeader title="投稿ページ" />
                <CardContent>
                  <div className={classes.siteLinks}>
                    <div className={classes.siteLinkContainer}>
                      <QRCodeImage
                        className={classes.qrImage}
                        value={userPage}
                        width={140}
                        height={140}
                      />
                      <div>
                        <Typography className={classes.siteLinkDesc}>
                          ユーザー投稿ページ
                        </Typography>
                        <Link
                          href={userPage}
                          className={classes.siteLink}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {userPage}
                        </Link>
                      </div>
                    </div>
                    <div className={classes.siteLinkContainer}>
                      <QRCodeImage
                        className={classes.qrImage}
                        value={artistPage}
                        width={140}
                        height={140}
                      />
                      <div>
                        <Typography className={classes.siteLinkDesc}>
                          アーティスト投稿ページ
                        </Typography>
                        <Link
                          href={artistPage}
                          className={classes.siteLink}
                          target="_blank"
                          rel="noreferrer"
                        >
                          {artistPage}
                        </Link>
                      </div>
                    </div>
                  </div>
                </CardContent>
              </Card>

              <Card>
                <CardHeader title="管理" />
                <CardContent>
                  <SquareButton
                    icon={<UserMessageIcon />}
                    variant="outlined"
                    onClick={goPage(`/concerts/${id}/user_messages`)}
                    className={classes.squareButton}
                  >
                    ユーザーメッセージ
                  </SquareButton>

                  <SquareButton
                    icon={<ArtistIcon />}
                    variant="outlined"
                    onClick={goPage(`/concerts/${id}/artist_messages`)}
                    className={classes.squareButton}
                  >
                    アーティストメッセージ
                  </SquareButton>

                  <SquareButton
                    icon={<SnapShotIcon />}
                    variant="outlined"
                    onClick={goPage(`/concerts/${id}/snap_shots`)}
                    className={classes.squareButton}
                  >
                    スナップショット
                  </SquareButton>

                  <SquareButton
                    icon={<NGWordIcon />}
                    variant="outlined"
                    onClick={goPage(`/concerts/${id}/ng_words`)}
                    className={classes.squareButton}
                  >
                    NGワード
                  </SquareButton>
                </CardContent>
              </Card>
            </div>
          </div>
          <Dialog
            open={dispImage}
            onClose={handleCloseDispImage}
            fullWidth={true}
            maxWidth="lg"
          >
            <DialogContent>
              <img
                src={concert.pseudo_screen_base_image_url}
                className={classes.dialogImage}
              />
              <div className={classes.previewLinks}>
                <PreviewLink
                  className={classes.previewLink}
                  href={concert.base_image_url}
                  text="ベース画像"
                />
                <PreviewLink
                  className={classes.previewLink}
                  href={concert.mask_for_joint_mask_image_url}
                  text="格子マスク用マスク"
                />
                <PreviewLink
                  className={classes.previewLink}
                  href={concert.restrict_image_url}
                  text="書き込み禁止エリア"
                />
                {concert.cmp_artist_message_image_url && (
                  <PreviewLink
                    className={classes.previewLink}
                    href={concert.cmp_artist_message_image_url}
                    text="合成アーティストメッセージ"
                  />
                )}
              </div>
            </DialogContent>
          </Dialog>

          <Dialog
            open={confirmOpen}
            onClose={handleCloseConfirm}
            fullWidth={true}
            maxWidth="sm"
          >
            <DialogContent>
              <DialogContentText>本当に削除しますか？</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseConfirm}>キャンセル</Button>
              <Button color="secondary" onClick={handleClickDelete}>
                OK
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      )}
    </DefaultLayout>
  )
}

export default ConcertShow
