export const svgToPng = async (svg: SVGSVGElement) => {
  const w = svg.getAttribute('width')
  const h = svg.getAttribute('height')

  if (!w || !h) {
    throw new Error('Undefined svg size')
  }
  const width = parseInt(w)
  const height = parseInt(h)

  const ser = new XMLSerializer()
  const svgAsXML = ser.serializeToString(svg)

  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    throw new Error('Failed to get context')
  }

  const loader = document.createElement('img')

  loader.width = canvas.width = width
  loader.height = canvas.height = height

  return new Promise<string>(resolve => {
    loader.onload = () => {
      ctx.drawImage(loader, 0, 0, loader.width, loader.height)
      resolve(canvas.toDataURL())
    }
    loader.src = 'data:image/svg+xml,' + encodeURIComponent(svgAsXML)
  })
}

export const trimBase64TypePrefix = (b64str: string) => {
  return b64str.replace(/data:.+;base64,/, '')
}

export interface Size {
  width: number
  height: number
}

export interface Rect extends Size {
  x: number
  y: number
}

export const getImageSize = (image: string): Promise<Size> => {
  const img = document.createElement('img')

  return new Promise<Size>(resolve => {
    img.onload = () => {
      resolve({
        width: img.width,
        height: img.height
      })
    }

    img.src = image
  })
}
