import React from 'react'

import Button, { ButtonProps } from '@material-ui/core/Button'

import { makeStyles, createStyles } from '@material-ui/core/styles'
const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: 160,
      height: 160
    },
    icon: {
      width: 64,
      height: 64,
      marginBottom: theme.spacing(1),
      display: 'block',

      '& svg': {
        width: 64,
        height: 64
      }
    }
  })
)

export interface SquareButtonProps extends ButtonProps {
  icon: any
}

export const SquareButton: React.FC<SquareButtonProps> = ({
  icon,
  children,
  ...rest
}) => {
  const classes = useStyles({})
  return (
    <Button {...rest}>
      <div className={classes.root}>
        <div className={classes.icon}>{icon}</div>
        <div>{children}</div>
      </div>
    </Button>
  )
}
