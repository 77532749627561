import React from 'react'

import { useHistory, useLocation } from 'react-router-dom'
import { useSnackbar } from 'notistack'

import { makeStyles } from '@material-ui/core/styles'

import { DateTime } from 'luxon'

import { PostConcertData, ConcertDupTimeError } from '../../lib/api_client'
import useAuthedApiClient from '../../lib/useAuthedApiClient'

import ConcertForm from './ConcertForm'

import DefaultLayout from '../../layout/DefaultLayout'

const useStyles = makeStyles(() => ({
  root: {}
}))

interface ConcertNewProps {}
const ConcertNew: React.FC<ConcertNewProps> = () => {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const { ymd } = location.state || { ymd: DateTime.local().toFormat('y-L-d') }

  const snack = useSnackbar()

  const client = useAuthedApiClient()

  const handleSave = async (data: PostConcertData) => {
    if (client) {
      try {
        const concert = await client.postConcert(data)
        history.push(`/concerts/${concert.hash_id}/show`)
      } catch (e) {
        if (e instanceof ConcertDupTimeError) {
          snack.enqueueSnackbar('開催日時が重複しています')
        } else {
          snack.enqueueSnackbar('登録に失敗しました')
        }
      }
    } else {
      snack.enqueueSnackbar('登録に失敗しました')
    }
  }

  return (
    <DefaultLayout title="新規のコンサート">
      <div className={classes.root}>
        <ConcertForm
          openingAt={ymd}
          closingAt={ymd}
          postClosingAt={ymd}
          onSave={handleSave}
        />
      </div>
    </DefaultLayout>
  )
}

export default ConcertNew
