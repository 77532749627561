import React, { useState } from 'react'

import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'

import { makeStyles, createStyles } from '@material-ui/core/styles'
const useStyles = makeStyles(theme =>
  createStyles({
    content: {
      minWidth: 400,
      paddingBottom: theme.spacing(3)
    }
  })
)

interface Props extends DialogProps {
  onSave: (word: string) => void
  onClose: () => void
}
const AddDialog: React.FC<Props> = ({ onSave, ...rest }) => {
  const [word, setWord] = useState('')

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWord(e.target.value)
  }

  const handleClick = () => {
    onSave(word)
    setWord('')
  }

  const classes = useStyles({})

  return (
    <Dialog {...rest}>
      <DialogTitle>新規NGワードの追加</DialogTitle>
      <DialogContent className={classes.content}>
        <TextField
          label="ワード"
          value={word}
          onChange={handleChange}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={rest.onClose}>キャンセル</Button>
        <Button variant="contained" color="secondary" onClick={handleClick}>
          登録
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddDialog
