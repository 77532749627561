import React from 'react'

import {
  MainImageKind,
  // MainImagePhotoFrame,
  ArtistNameKind,
  ArtistNameColor,
  MainImagePhotoFrame
  // BgKind
} from '../types'
import { Size } from '../../../lib/imaging'
import TextColor, { toHexColor } from '../TextColor'
import BmpText from './BmpText'
import { PhotoFrame, getForeImagePath, getBackImagePath } from './PhotoFrame'
import { useFetchBase64 } from '../../../lib/use-fetch-base64'
import { XY, copyrightTapeHeight } from './arrangement'

const fontPath = '/fonts/GenEiGothicN-U-KL.woff2'
const copyrightFontPath = '/fonts/NotoSansCJKtc-Medium.woff2'
const copyrightBackImagePath = '/images/copyright_material.png'

interface ArtistNameDateProps {
  artistNameTextRef: React.RefObject<any>
  dateTextRef: React.RefObject<any>

  artistName: string
  artistNameKind: ArtistNameKind
  artistNamePos: XY
  artistNameFontSize: number
  artistNameLetterSpacing: number
  artistNameColor: TextColor
  artistNameLogoImage: string | null
  artistNameLogoImageSize: Size

  dateText: string
  datePos: XY
  dateColor: TextColor
  dateFontSize: number

  rotation: number
}
const ArtistNameDate: React.FC<ArtistNameDateProps> = ({
  artistNameTextRef,
  dateTextRef,

  artistName,
  artistNameKind,
  artistNamePos,
  artistNameFontSize,
  artistNameLetterSpacing,
  artistNameColor,
  artistNameLogoImage,
  artistNameLogoImageSize,

  dateText,
  datePos,
  dateColor,
  dateFontSize,

  rotation
}) => {
  const jaEffectImg = useFetchBase64(() => {
    if (artistNameKind === ArtistNameKind.Ja) {
      if (artistNameColor === TextColor.White) {
        return '/images/ja_effect/type_a.png'
      } else if (
        [TextColor.Blue, TextColor.Green, TextColor.Yellow].includes(
          artistNameColor
        )
      ) {
        return '/images/ja_effect/type_b.png'
      } else {
        return '/images/ja_effect/type_c.png'
      }
    } else {
      return null
    }
  }, [artistNameKind])
  return (
    <g>
      {artistNameKind === ArtistNameKind.En && (
        <g
          transform={`translate(${artistNamePos.x}, ${artistNamePos.y}) rotate(${rotation})`}
        >
          <BmpText
            ref={artistNameTextRef}
            textAnchor="middle"
            text={artistName}
            fontSize={artistNameFontSize}
            color={artistNameColor}
          />
        </g>
      )}

      {artistNameKind === ArtistNameKind.Ja && (
        <g
          transform={`translate(${artistNamePos.x}, ${artistNamePos.y}) rotate(${rotation})`}
        >
          <defs>
            <mask id="text-mask">
              <text
                textAnchor="middle"
                dominantBaseline="text-before-edge"
                fontSize={artistNameFontSize}
                style={{ fill: '#fff' }}
                fontFamily="'GenEiGothicN"
                letterSpacing={artistNameLetterSpacing}
              >
                {artistName}
              </text>
            </mask>
          </defs>

          <text
            ref={artistNameTextRef}
            textAnchor="middle"
            dominantBaseline="text-before-edge"
            fontSize={artistNameFontSize}
            style={{ fill: toHexColor(artistNameColor) }}
            fontFamily="'GenEiGothicN"
            letterSpacing={artistNameLetterSpacing}
          >
            {artistName}
          </text>

          {jaEffectImg && (
            <image
              xlinkHref={jaEffectImg}
              x={-1922 / 2}
              mask="url(#text-mask)"
            />
          )}
        </g>
      )}
      {artistNameKind === ArtistNameKind.Logo && artistNameLogoImage && (
        <g transform={`translate(${artistNamePos.x}, ${artistNamePos.y})`}>
          <image
            x={
              -(
                (artistNameLogoImageSize.width /
                  artistNameLogoImageSize.height) *
                artistNameFontSize
              ) / 2
            }
            y={0}
            xlinkHref={artistNameLogoImage}
            height={artistNameFontSize}
          />
        </g>
      )}

      <g
        transform={`translate(${datePos.x}, ${datePos.y}) rotate(${rotation})`}
      >
        <BmpText
          ref={dateTextRef}
          textAnchor="middle"
          text={dateText}
          fontSize={dateFontSize}
          color={dateColor}
        />
      </g>
    </g>
  )
}

interface CopyrightTapeProps {
  copyrightRef: React.RefObject<any>
  copyrightBackImage: string | null
  copyrightMaskPolygon: XY[]
  copyright: string | null
}
const CopyrightTape: React.FC<CopyrightTapeProps> = ({
  copyrightRef,
  copyrightBackImage,
  copyrightMaskPolygon,
  copyright
}) => {
  return (
    <g>
      <mask id="copyright-back-mask">
        <polygon
          points={copyrightMaskPolygon.map(({ x, y }) => `${x},${y}`).join(' ')}
          fill="#fff"
        />
      </mask>
      {copyrightBackImage && (
        <image
          xlinkHref={copyrightBackImage}
          x={-612 / 2}
          y={0}
          mask="url(#copyright-back-mask)"
        />
      )}

      <text
        ref={copyrightRef}
        textAnchor="middle"
        dominantBaseline="text-before-edge"
        fontSize="12pt"
        y={-2} // 微調整
        style={{ fill: '#f1f1f1' }}
        fontFamily="NotoSansMedium"
      >
        {copyright}
      </text>
    </g>
  )
}

interface BaseImageSvgProps {
  className?: string
  svgRef: React.RefObject<SVGSVGElement>
  artistNameTextRef: React.RefObject<any>
  dateTextRef: React.RefObject<any>
  copyrightRef: React.RefObject<any>
  width: number
  height: number
  mainImageKind: MainImageKind
  photoFrame: PhotoFrame
  mainImagePhotoFrame: MainImagePhotoFrame | null
  illustSize: Size
  mainImageUrl: string | null
  artistName: string
  artistNameKind: ArtistNameKind
  artistNamePos: XY
  artistNameFontSize: number
  artistNameLetterSpacing: number
  artistNameColor: ArtistNameColor
  artistNameLogo: string | null
  artistNameLogoImageSize: Size
  rotation: number
  dateText: string
  dateColor: number
  dateFontSize: number
  datePos: XY
  copyright: string | null
  copyrightMaskPolygon: XY[]
}
const BaseImageSvg: React.FC<BaseImageSvgProps> = ({
  className,
  svgRef,
  artistNameTextRef,
  dateTextRef,
  copyrightRef,
  width,
  height,
  mainImageKind,
  illustSize,
  mainImageUrl,
  photoFrame,
  mainImagePhotoFrame,
  artistName,
  artistNameKind,
  artistNameLogo,
  artistNamePos,
  artistNameFontSize,
  artistNameLetterSpacing,
  artistNameColor,
  artistNameLogoImageSize,
  rotation,
  dateText,
  dateColor,
  dateFontSize,
  datePos,
  copyright,
  copyrightMaskPolygon
}) => {
  const font = useFetchBase64(fontPath, [])
  const copyrightFont = useFetchBase64(copyrightFontPath, [])

  const mainImage = useFetchBase64(() => {
    return mainImageUrl
  }, [mainImageUrl])

  const photoFrontImage = useFetchBase64(() => {
    return getForeImagePath(mainImagePhotoFrame)
  }, [mainImagePhotoFrame])

  const photoBackImage = useFetchBase64(() => {
    return getBackImagePath(mainImagePhotoFrame)
  }, [mainImagePhotoFrame])

  const artistNameLogoImage = useFetchBase64(() => {
    if (artistNameKind === ArtistNameKind.Logo && artistNameLogo) {
      return artistNameLogo
    } else {
      return null
    }
  }, [artistNameKind, artistNameLogo])

  const copyrightBackImage = useFetchBase64(copyrightBackImagePath, [])

  const enableMainImage = mainImageKind >= 1
  return (
    <svg
      className={className}
      ref={svgRef}
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      // style={{ backgroundColor: 'white' }}
    >
      <defs>
        {font && (
          <style type="text/css">
            {`
          @font-face {

            font-family: GenEiGothicN;
            src: url("${font}") format("woff2");
          }
          `}
          </style>
        )}
        {copyrightFont && (
          <style type="text/css">
            {`
          @font-face {

            font-family: NotoSansMedium;
            src: url("${copyrightFont}") format("woff2");
          }
          `}
          </style>
        )}
      </defs>

      <g>
        {enableMainImage && (
          <>
            {/* イラスト */}
            {mainImageKind === MainImageKind.Illust && mainImage && (
              <image
                x={(width - illustSize.width) / 2}
                y={200}
                width={illustSize.width}
                height={illustSize.height}
                xlinkHref={mainImage}
              />
            )}

            {/* 写真 */}
            {mainImageKind === MainImageKind.Photo && mainImage && (
              <g
                transform={`translate(${photoFrame.out.x}, ${photoFrame.out.y})`}
              >
                {photoBackImage && (
                  <image xlinkHref={photoBackImage} x="0" y="0" />
                )}

                <g
                  transform={`translate(${
                    (photoFrame.out.width - photoFrame.in.width) / 2 +
                      3 /* 調整 */
                  }, ${(photoFrame.out.height - photoFrame.in.height) /
                    2}) rotate(0.5)`}
                >
                  <image
                    xlinkHref={mainImage}
                    width={photoFrame.in.width}
                    height={photoFrame.in.height - (copyright ? 24 : 0)}
                    preserveAspectRatio="xMidYMid slice"
                  />
                </g>
                <g
                  transform={`translate(${
                    (photoFrame.out.width - photoFrame.in.width) / 2 -
                      4 /* 調整 */
                  }, ${(photoFrame.out.height - photoFrame.in.height) /
                    2}) rotate(0.5)`}
                >
                  {copyright && (
                    <text
                      ref={copyrightRef}
                      x={photoFrame.out.width / 2}
                      y={photoFrame.in.height - 24 + 2 /* 調整 */}
                      textAnchor="middle"
                      dominantBaseline="text-before-edge"
                      fontSize="9pt"
                      style={{ fill: '#1e1e1e' }}
                      fontFamily="NotoSansMedium"
                    >
                      {copyright}
                    </text>
                  )}
                </g>
                {photoFrontImage && (
                  <image xlinkHref={photoFrontImage} x="0" y="0" />
                )}
              </g>
            )}
          </>
        )}

        {/* TODO: 英語はビットマップフォント */}

        <ArtistNameDate
          artistNameTextRef={artistNameTextRef}
          dateTextRef={dateTextRef}
          artistName={artistName}
          artistNameKind={artistNameKind}
          artistNamePos={artistNamePos}
          artistNameFontSize={artistNameFontSize}
          artistNameLetterSpacing={artistNameLetterSpacing}
          artistNameColor={artistNameColor}
          artistNameLogoImage={artistNameLogoImage}
          artistNameLogoImageSize={artistNameLogoImageSize}
          dateText={dateText}
          dateColor={dateColor}
          datePos={datePos}
          rotation={rotation}
          dateFontSize={dateFontSize}
        />
      </g>

      {copyright && (
        <>
          {mainImageKind === MainImageKind.Illust && (
            <g
              transform={`translate(${artistNamePos.x}, ${705 -
                15 -
                copyrightTapeHeight}) rotate(-3)`}
            >
              <CopyrightTape
                copyrightBackImage={copyrightBackImage}
                copyrightMaskPolygon={copyrightMaskPolygon}
                copyrightRef={copyrightRef}
                copyright={copyright}
              />
            </g>
          )}
          {mainImageKind === MainImageKind.None &&
            artistNameKind === ArtistNameKind.Logo && (
              <g
                transform={`translate(${datePos.x}, ${datePos.y +
                  dateFontSize +
                  15})`}
              >
                <CopyrightTape
                  copyrightBackImage={copyrightBackImage}
                  copyrightMaskPolygon={copyrightMaskPolygon}
                  copyrightRef={copyrightRef}
                  copyright={copyright}
                />
              </g>
            )}
        </>
      )}
    </svg>
  )
}

export default BaseImageSvg
