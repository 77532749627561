import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'

const MusicianIcon = () => {
  return (
    <SvgIcon viewBox="0 0 490.531 490.531">
      <g id="XMLID_169_">
        <path
          id="XMLID_176_"
          d="M315.623,124.775c0.195,0.981,0.704,1.864,0.783,2.878c0.244,3.444-0.254,6.824-1.422,9.967
		c3.1,7.142,2.06,15.759-3.416,22.047c-1.332,1.51-2.844,2.806-4.51,3.859c-0.816,1.809-1.902,3.526-3.26,5.109
		c-1.311,1.493-2.814,2.773-4.442,3.818c-0.814,1.798-1.904,3.486-3.216,4.996c-3.945,4.601-9.701,7.248-15.773,7.248
		c-1.326,0-2.639-0.127-3.934-0.375l-13.379,15.424c1.186,3.163,1.707,6.385,2.334,9.599c0.736-0.456,1.568-0.712,2.256-1.262
		l58.178-47.25c8.777-7.121,10.104-20.001,2.99-28.778C325.391,127.852,320.614,125.447,315.623,124.775z"
        />
        <path
          id="XMLID_175_"
          d="M107.422,275.39c-1.949-4.635-3.451-9.382-4.46-14.271c0,9.677-0.06,195.183-0.06,204.859
		c0,13.563,10.995,24.554,24.55,24.554c13.549,0,24.546-10.991,24.546-24.554V319.929c-7.987-3.396-15.617-7.941-22.453-13.872
		C119.636,297.454,112.269,286.859,107.422,275.39z"
        />
        <path
          id="XMLID_174_"
          d="M169.899,325.233v140.744c0,13.563,11.003,24.554,24.553,24.554c13.555,0,24.552-10.991,24.552-24.554
		V315.243c-10.439,7.18-23.015,11.167-36.774,11.167C178.099,326.41,173.978,325.921,169.899,325.233z"
        />
        <path
          id="XMLID_173_"
          d="M160.934,95.668c26.436,0,47.857-21.409,47.857-47.83C208.791,21.408,187.369,0,160.934,0
		c-26.418,0-47.822,21.408-47.822,47.838C113.112,74.259,134.516,95.668,160.934,95.668z"
        />
        <path
          id="XMLID_172_"
          d="M423.866,4.586c-0.796-0.632-1.866-0.854-2.857-0.607l-46.592,11.622
		c-2.414,0.599-4.109,2.762-4.109,5.248v47.273c-0.384-0.042-0.705-0.225-1.088-0.225c-6.602,0-11.958,5.355-11.958,11.957
		c0,6.592,5.356,11.947,11.958,11.947c6.602,0,11.957-5.356,11.957-11.947V34.116l33.115-8.265v28.206
		c-0.384-0.041-0.699-0.227-1.088-0.227c-6.602,0-11.95,5.356-11.95,11.957c0,6.592,5.349,11.948,11.95,11.948
		c6.601,0,11.957-5.356,11.957-11.948V7.233C425.16,6.2,424.68,5.227,423.866,4.586z"
        />
        <path
          id="XMLID_171_"
          d="M272.963,163.345l4.492,3.891c0.852,0.734,1.889,1.097,2.912,1.097c1.246,0,2.473-0.529,3.354-1.544
		c1.617-1.855,1.405-4.665-0.449-6.283l-4.473-3.874l1.933-2.246l4.48,3.894c0.844,0.727,1.885,1.085,2.923,1.085
		c1.246,0,2.475-0.52,3.374-1.532c1.6-1.863,1.405-4.669-0.448-6.281l-4.493-3.885l1.951-2.246l4.492,3.885
		c0.826,0.736,1.867,1.094,2.893,1.094c1.247,0,2.475-0.521,3.369-1.535c1.617-1.855,1.41-4.659-0.443-6.273l-4.492-3.89l3.98-4.59
		c2.639-3.036,2.316-7.632-0.735-10.271l-11.397-9.884c-1.375-1.2-3.07-1.783-4.765-1.783c-2.043,0-4.073,0.849-5.515,2.511
		l-3.98,4.58l-4.475-3.894c-0.844-0.727-1.865-1.088-2.906-1.088c-1.244,0-2.498,0.519-3.372,1.534
		c-1.601,1.854-1.394,4.668,0.444,6.282l4.491,3.885l-1.946,2.237l-4.478-3.885c-0.845-0.727-1.887-1.085-2.907-1.085
		c-1.245,0-2.491,0.521-3.372,1.534c-1.6,1.855-1.41,4.666,0.431,6.274l4.491,3.884l-1.933,2.246l-4.491-3.884
		c-0.851-0.73-1.891-1.089-2.911-1.089c-1.246,0-2.493,0.52-3.374,1.525c-1.611,1.864-1.405,4.67,0.449,6.282l4.492,3.894
		l-29.366,33.838c-5.927-2.63-12.114-3.949-18.041-3.949c-9.799-0.01-18.959,3.596-25.257,10.859
		c-3.934,4.541-6.15,10.039-7.031,15.883l17.455,6.655c7.643-7.313,19.611-7.982,27.763-0.909
		c8.661,7.509,9.593,20.633,2.079,29.297c-1.6,1.854-3.568,3.171-5.581,4.307c0.273,4.988-0.431,10.111-2.314,15.082
		c-5.593,14.689-19.58,23.721-34.413,23.721c-4.363,0-8.807-0.777-13.123-2.423l-43.316-16.511
		c2.973,12.322,9.877,24.406,20.855,33.926c12.553,10.885,27.7,16.352,41.963,16.352c13.857,0,26.866-5.164,35.852-15.522
		c10.32-11.907,13.086-28.131,9.043-43.842c6.092-1.607,11.574-4.812,15.763-9.646c10.114-11.669,9.841-29.257,0.735-43.843
		L272.963,163.345z"
        />
        <path
          id="XMLID_170_"
          d="M175.85,262.333c8.23,0,16-5.022,19.111-13.179c4.031-10.558-1.276-22.378-11.821-26.407l-70.271-26.781
		l6.346-15.103l21.854,8.327c5.465-2.006,11.172-3.54,17.275-4.044c1.854-4.061,4.256-7.833,7.18-11.203
		c9.525-10.982,23.64-16.503,37.626-16.503c4.219,0,8.517,0.477,12.771,1.421l13.38-15.434c-1.667-4.88-1.412-10.286,0.699-15.12
		l-12.305-15.455c-4.254-5.332-10.631-7.616-16.959-7.327c-7.482-1.21-74.213-0.736-79.09-0.106
		c-8.504-0.605-16.813,3.957-20.283,12.26c-26.93,64.182-19.465,46.407-34.394,81.95c-4.491,10.73,0.827,22.943,11.567,27.033
		l90.025,34.318C170.957,261.892,173.418,262.333,175.85,262.333z"
        />
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </SvgIcon>
  )
}
export default MusicianIcon
